.blog-section{
    position: relative;
    width: 100%;
    margin-top: 50px;
    @include screen-md-max {
        margin-top: 30px;
    }
    .block-container {
        display: flex;
        gap: 30px;
        // justify-content: space-between;
        @include screen-lg-max {
            width: $mobile-container-width;
            flex-direction: column;
        }
        .blog-content {
            .blog-properties {
                .date {
                    font-size:12px;
                    line-height: 20px;
                }
                .tag {
                    font-size:12px;
                    line-height: 20px;
                }
            }
            .blog-title {            
                font-family: var(--section-blog--title-font-family);
                font-size: var(--section-blog--title-font-size);
                line-height: var(--section-blog-title-line-height);
                margin-bottom: 12px;
                margin-top: 20px;
                font-weight: var(--section-blog-title-font-weight-desktop);
                @include screen-md-max {
                    font-weight: var(--section-blog-title-font-weight-mobile);
                }
            }
            .blog-text {
                > * {
                    margin-bottom: 15px;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
            .tag {
                color: var(--section-blog--tag-color);
                text-decoration: none;
            }   
        }
        .column-left {
            display:flex;
            flex-direction: column;
            width: 790px;
            .blog-image {
                width:100%;                       
            }
            .blog-content {
                margin-top:30px;
                padding:0px 10px;
                height:100%;
                position:relative;
                @include screen-lg-max {
                    margin-top: 20px;
                }            
    
                .blog-title{
                    margin-top: 12px;
                }
                
                .blog-share{
                    margin-top:50px;
                    height:270px;
    
                    @include screen-lg-max{
                        height: 100px;
                    }
                }
                .back {
                    position:absolute;
                    bottom:0px;
                    font-weight: 700;
                }
    
                #st-1{
                    font-family: var(--section-blog--share-button-font);
                    font-weight: 700;
                    display:flex;
    
                    .st-btn {
                        font-size: 15px;
                        border-radius: 25px;
                    }
                }
            }
            @include screen-lg-max {
                width:100%;
            }
        }
    
        .column-right {
            width: 380px;
            @include screen-lg-max {
                margin-top:25px;
                width:100%;
            }
    
            .template-blogs-page{
                position:relative;
                .banner{
                    position:absolute;
                    background-color: var(--section-blog--related-banner-color);
                    display:block;
                    padding: 10px 20px;
                    top: 25px;
                    .banner-title{
                        font-size: var(--section-template-blogs-page-banner-font-size);
                        font-family: var(--section-template-blogs-page-banner-font-family);
                        line-height: var(--section-template-blogs-page-banner-line-height);
                        font-weight: normal;
                        color: var(--section-blog--related-banner-title-color);
                        @include screen-md-max {
                            font-size: var(--section-template-blogs-page-banner-font-size-mobile);
                            line-height: var(--section-template-blogs-page-banner-line-height-mobile);
                        }
                    }
                }
    
                &.loading .banner{
                    display:none;
                }
    
                .items{
                    display:flex;
                    flex-direction: column;                
    
                    .blog-item{
                       
                        display:flex;
                        flex-direction: column;
                        box-shadow: 2px 2px 5px rgba(0,0,0,.2);
                        margin-bottom:30px;
    
                        .blog-content{
                            padding: 20px 20px 30px;   
                            
                            a{
                                color:inherit;
                                text-decoration: none;
                            }
    
                            .tag{
                                color: var(--section-blog--tag-color);
                                text-decoration: none;
                            }
                        }
    
                        .blog-image{
                            width:100%;
                        }
                        &:last-child{
                            margin-bottom:0px;
                        }
                    }
                }
            }
            @include screen-lg-max {
                width:100%;
            }
        }
    }
}