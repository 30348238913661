.dropdown-select {
  position: relative;
  width: 100%;
  height: 40px;
  background: var(--dropdown-select--background);
  border-radius: 5px;
  &.region-dropdown {
    max-width: 50%;
    margin-bottom: 36px;
    @include screen-md-max {
      max-width: 100%;
    }
    .venue-selector {
      .states-container {
        border-bottom: 0;
        padding-bottom: 0;
        margin: 0;
        .list-states {
          display: flex;
          flex-direction: column;
          width: 100%;
          .state {
            padding: 10px 15px;
            font-size: 16px;
            font-weight: 400;
            width: 100%;
            text-align: left;
            &:hover {
              background: #f9e360;
            }
            &.active {
              border-bottom: 0;
              font-weight: 600;
              color: #000000;
              text-decoration: none;
            }
          }
        }
      }
    }
    .venue-selector-mobile.show {
      .selector-mobile-header {
        background: #f9e360;
        color: #000;
        padding: 10px 15px;
      }
      .states-container {
        padding: 0;
        border-bottom: 0;
        .list-states {
          display: flex;
          flex-direction: column;
          width: 100%;
          .state {
            padding: 10px 15px;
            width: 100%;
            text-align: left;
            &:hover {
              background: #f9e360;
            }
            &.active {
              border-bottom: 0;
              font-weight: 600;
              color: #000000;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .click-area {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }
  .dropdown-icon {
    position: absolute;
    width: 22px;
    right: 5px;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 15;
    pointer-events: none;
  }
  .input-text {
    display: flex;
    align-items: center;
    color: var(--dropdown-select--bookdatepicker-placeholder-colour);
    font-weight: 400;
    border: 1px solid var(--dropdown-select--input-text-border);
    height: 40px;
    width: 100%;
    padding: 0 15px;
  }
  .input-text.active {
    color: var(--dropdown-select--input-text-active-colour);
  }
  .venue-selector {
    position: absolute;
    display: none;
    z-index: 20;
    width: 100%;    
    background: var(--dropdown-select--venue-selector-background);
    border: 1px solid var(--dropdown-select--venue-selector-border);
    @include screen-md-max {
      display: none;
    }
    .states-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 10px;
      margin: 10px 15px 0px 15px;
      border-bottom: 1px solid var(--dropdown-select--states-border-bottom);
      ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          font-weight: 600;
          font-size: 11px;
          color: var(--dropdown-select--states-list-colour);
          text-transform: uppercase;
          cursor: pointer;
          padding-bottom: 1px;
          border-bottom: 1px solid transparent;          
        }
        .state.active {
          border-bottom: 2px solid var(--dropdown-select--states-list-active-colour);
          font-weight: var(--dropdown-select--states-list-active-font-weight);
          color: var(--dropdown-select--states-list-active-text-color);
        }
      }
    }
    .venues-container {
      padding: var(--dropdown-select--venues-container-padding);
      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: var(--dropdown-select--venues-container-ul-padding);
        li {
          margin: 0;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          padding: 3px 15px;
        }
        li:hover {
          color: var(--dropdown-select--venues--hover-colour);
          background-color: var(--dropdown-select--venues-hover-bgcolor);
          
        }
      }
      .venue {
        display: none;
        color: var(--dropdown-select--venues-colour);
        animation: fade-out $transition-03s;
      }
      .venue.show {
        display: flex;
        animation: fade-in $transition-03s;
      }
    }
  }
  .venue-selector.show,
  .venue-selector-mobile.show {
    display: block;
  }

  .venue-selector-mobile {
    position: fixed;
    display: none;
    flex-direction: column;
    bottom: -100vh;
    left: 0;
    width: 100%;
    background: var(--block-book--venue-selector-mobile);
    height: 70vh;
    z-index: 50;
    transition: bottom $transition-03s;
    @include screen-md-max {
      display: flex;
    }
    .states-container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px;
      border-bottom: 1px solid var(--block-book--venue-selector-mobile-states-border);
      ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          font-weight: 600;
          font-size: 16px;
          color: var(--block-book--venue-selector-mobile-states-colour);
          text-transform: uppercase;
          cursor: pointer;
          padding-bottom: 1px;
          border-bottom: 1px solid transparent;
        }
        .state.active {
          border-bottom: 2px solid var(--block-book--venue-selector-mobile-states-border-active);
          color: var(--block-book--venue-selector-mobile-states-colour-active);
        }
      }
    }
    .venues-container {
      padding: 10px 20px;
      overflow-y: scroll;
      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin: 0;
          padding: 0;
          font-weight: 400;
          font-size: 18px;
          padding: 8px 0;
          cursor: pointer;
        }
        li:hover {
          color: var(--block-book--venue-li-hover-colour);
        }
      }
      .venue {
        display: none;
        color: var(--block-book--venue-colour);
        animation: fade-out $transition-03s;
      }
      .venue.show {
        display: block;
        animation: fade-in $transition-03s;
      }
    }
  }

  .venue-selector-mobile.show {
    bottom: 0vh;
    overflow-y: scroll;
    .states-container {
      position: sticky;
      top: 0;
      left: 0;
      background: #ffffff;
      z-index: 10;
    }
  }
  
  // Correct ME
  .flatpickr {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: var(--dropdown-select--bookdatepicker-border-radius);
    border: 1px solid var(--dropdown-select--bookdatepicker-border);
    padding: 0 15px;
    color: var(--dropdown-select--bookdatepicker-colour) !important;
    font-weight: 400;
    margin: 0;
    height: 100%;
    font-family: inherit;
    background: var(--dropdown-select--bookdatepicker-background);
    font-size: inherit;
    line-height: inherit;
  }
  .flatpickr::placeholder {
    color: var(--dropdown-select--bookdatepicker-placeholder-colour) !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  appearance: none;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  font-weight: 600;
  color: var(--dropdown-select--colour);
}

select::-ms-expand {
  display: none;
}

.flatpickr-calendar {
  font-family: $fontSecondary !important;
  border-radius: 0px !important;
  border: 1px solid var(--dropdown-select--flatpickr-calendar-border) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-months {
  border-bottom: 1px solid var(--dropdown-select--flatpickr-months-border) !important;
}

.flatpickr-current-month {
  padding: 9px 0 !important;
  font-size: 13px !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: var(--neutral-black) !important;
  text-transform: uppercase !important;
  text-align: center !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  font-weight: 700 !important;
}

.flatpickr-current-month select {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
}

.flatpickr-current-month select::-ms-expand {
  display: none !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  padding: 3px 10px !important;
}

.flatpickr-day {
  font-weight: var(--dropdown-select--flatpickr-day-font-weight) !important;
  &:hover {
    background: var(--dropdown-select--flatpickr-day-today-background-colour) !important;
    color: var(--dropdown-select--flatpickr-day-today-colour) !important;
  }
}

.flatpickr-day.today {
  border-color: var(--dropdown-select--flatpickr-day-today-border-colour) !important;
}

span.flatpickr-weekday {
  font-weight: var(--dropdown-select--flatpickr-weekday-font-weight) !important;
  color: var(--dropdown-select--flatpickr-weekday-colour) !important;
}

.flatpickr-day.selected {
  background: var(--dropdown-select--flatpickr-day-selected-background) !important;
  color: var(--dropdown-select--flatpickr-day-selected-colour) !important;
  border-color: var(--dropdown-select--flatpickr-day-selected-border) !important;
}

.flatpickr-rContainer {
  padding: 10px 0 !important;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  display: none !important;
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  fill: var(--dropdown-select--flatpickr-months-svg-fill);
}

.flatpickr-mobile {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  padding: 0 15px;
}

input[type="date"] {
  position: absolute;
  left: 0;
  top: 0;
  text-align: left;
  background: var(--dropdown-select--input-date-background);
  width: 100%;
  min-width: 95%;
}

input::-webkit-date-and-time-value {
  text-align: left;
  margin: 0;
  padding: 0 8px;
  color: var(--dropdown-select--input-date-color) !important;
}

input::placeholder {
  color: var(--dropdown-select--input-date-color) !important;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}