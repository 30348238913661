html,
body {
    font-family: $fontPrimary;
    font-size: $browser-context;
    color: $body-color;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

h2,
h3,
h4 {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $fontPrimary;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
p:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: map_get($h1, 'fontSize');
  line-height: map_get($h1, 'lineHeight');
  @include screen-md-max {
    font-size: map_get($h1Mobile, 'fontSize');
    line-height: map_get($h1Mobile, 'lineHeight');
  }
}

h2 {
  font-size: map_get($h2, 'fontSize');
  line-height: map_get($h2, 'lineHeight');
  @include screen-md-max {
    font-size: map_get($h2Mobile, 'fontSize');
    line-height: map_get($h2Mobile, 'lineHeight');
  }
}

h3 {
  font-size: map_get($h3, 'fontSize');
  line-height: map_get($h3, 'lineHeight');
}

h4 {
  font-size: map_get($h4, 'fontSize');
  line-height: map_get($h4, 'lineHeight');
}

h5 {
  font-size: map_get($h5, 'fontSize');
  line-height: map_get($h5, 'lineHeight');
}

p {
  font-size: map_get($p, 'fontSize');
  line-height: map_get($p, 'lineHeight');
  @include screen-md-max {
    font-size: map_get($pMobile, 'fontSize');
    line-height: map_get($pMobile, 'lineHeight');
  }
}

li {
  font-size: map_get($p, 'fontSize');
  line-height: map_get($p, 'lineHeight');
  @include screen-md-max {
    font-size: map_get($pMobile, 'fontSize');
    line-height: map_get($pMobile, 'lineHeight');
  }
}

a {
  color: var(--typography-link-colour);
  text-decoration: underline;
  cursor: pointer;
}

a:hover {
  color: var(--typography-link-hover-colour);
  text-decoration: none;
}

.small-text {
  font-size: 14px;
  line-height: 20px;
}

.subheading {
  font-weight: 600;
}

.button-text {
  font-size: 28px;
  line-height: 28px;
  @include screen-md-max {
    font-size: 20px;
    line-height: 20px;
  }
}

svg.icon {
  fill: currentColor;
  color: var(--icon-colour);
}