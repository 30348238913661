.block-text-hero-carousel {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  
  @include screen-md-max {
    height: unset;
    margin: auto;
    overflow: visible;
  }
  &.single-item{
    @include screen-md-max {
      width: $mobile-container-width;
    }
  }
  .carousel-container {
    position: relative;
    width: 100%;
    flex-grow: 1;
    margin: 0 auto;
    padding: 0px;
    .swiper {
      position: relative;
      width: 100%;
      overflow: hidden;
      @include screen-xl-max {
        width: 100%;
      }
      .swiper-wrapper {
        overflow: visible !important;
        .swiper-slide {
          display: flex;
          flex-direction: column;
          height: unset !important;
          overflow: hidden;
          .image-container {
            position: relative;
            width: 100%;
            overflow: hidden;
            background: var(--block-gallery--image-background);
            @include screen-md-max{
              height: auto;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 50%;
              @include screen-md-max{
                height:auto;
              }
            }
          }
          .cta-container{
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 40px;
            @include screen-md-max{
              bottom: 20px;
            }
            .cta{
              text-decoration: none;
              display: inline-block;
              @include screen-md-max{
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 999999;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: '';
  }
  .swiper-button-prev {
    background-image: initial;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99998;
    @include screen-md-max {
      left: 15px;
      top: 55%;
    }
  }
  .swiper-button-next {
    background-image: initial;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99998;
    @include screen-md-max {
      right: 15px;
      top: 55%;
    }
  }
  .swiper-pagination {
    position: relative !important;
    padding: 50px 0;
    @include screen-md-max{
      padding: 30px 0px !important;
    }
  }
  .swiper-pagination-bullet {
    pointer-events: all !important;
    width: 22px;
    height: 22px;
  }
  .swiper-pagination-bullet-active {
    background: var(--block-gallery--swiper-pagination-bullet-active);
  }
}