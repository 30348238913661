// desktop
.component-header {
    .header-container {
        .header-container-wrapper {
            .top {
                .navigation-container {
                    .sub-menu-list {
                        .sub-menu {
                            .category {
                                font-size: 18px;
                            }
                        }
                        .button-menu {
                            .button-items {
                                margin-top: 25px;
                                .item {
                                    min-width: 180px;
                                    text-decoration: none;
                                    .desktop-label {
                                        font-weight: 700;
                                        font-size: 20px;
                                        line-height: 27px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// mobile
.component-header {
    .mobile-navigation {
        .mobile-navigation-container {
            .mobile-navigation-items {
                .sub-menu {
                    .category-items {
                        .item {
                            .icon-spare {
                                width: 13px;
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.component-header.scroll-down {
    .header-container {
        padding: 0;
        .header-container-wrapper {
            padding: 0;
            @include screen-xl-max {
                width: $mobile-container-width;
                padding: 15px 0;
            }
            .top {
                .logo-container {
                    width: 103px;
                    @include screen-lg-max {
                        width: var(--header-logo-container-width);
                    }
                }
            }
        }
    }
}