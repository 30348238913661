.block-book-now {
  position: relative;
  display: flex;
  &.hide-date {
    .block-container {
      .dropdown-container {
        .dropdown-wrapper {
          .dropdown.date {
            display: none;
          }
        }
      }
    }
  }
  .block-container {
    display: flex;
    flex-direction: column;
    @include screen-xl-max {
      width: 100%;
    }
  }
  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--block-book--title-background);
    padding: 16px 0;
    .title {
      color: var(--block-book--title-colour);
      text-transform: uppercase;
      text-align: center;
      font-weight: 700;
      font-size: 42px;
      @include screen-md-max {
        font-size: 25px;
        line-height: 30px;
        width: 70%;
      }
    }
  }
  .dropdown-container {
    display: flex;
    background: var(--block-book--dropdown-background);
    @include screen-md-max {
      width: 100%;
    }
    .dropdown-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 32px auto;
      width: 768px;
      @include screen-xl-max {
        width: 90%;
      }
      @include screen-md-max {
        width: 100%;
        flex-direction: column;
        margin: 0;
        align-items: center;
        padding: 30px;
      }
      .dropdown {
        flex: 1;
        margin-right: 10px;
        @include screen-md-max {
          width: 100%;
          margin: 0;
          margin-bottom: 20px;
        }
        .dropdown-select{
          border-radius: var(--dropdown-select--bookdatepicker-border-radius);
          height: var(--dropdown-select--bookdatepicker-height);
          @include screen-md-max {
            height: var(--dropdown-select--bookdatepicker-height-mobile);
          }
          .input-text {
            color: var(--dropdown-select--bookdatepicker-placeholder-colour);
            height: var(--dropdown-select--bookdatepicker-height);
            @include screen-md-max {
              height: var(--dropdown-select--bookdatepicker-height-mobile);
            }
          }
        }
        .dropdown-title {
          color: var(--block-book--dropdown-title-colour);
          font-weight: var(--block-book--dropdown-title-font-weight);
          font-size: var(--block-book--dropdown-title-font-size);
          text-transform: var(--block-book--dropdown-title-text-transform);
          margin-bottom: 15px;
          @include screen-md-max {
            font-size: var(--block-book--dropdown-title-font-size-mobile);
          }
        }
      }
      .btn-block-book-now {
        background: var(--block-book--button-background);
      }
    }
  }
}

@include screen-md-max {
  .block-text-hero + .block-book-now{
    margin-top:0px;
  }
}