.block-contact {
    display: block;
    .block-container {
        display: flex;
        flex-direction: column;
        .title-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
            @include screen-md-max {
                margin-bottom: 30px;
            }
            .title {
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        .body-container {
            display: flex;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
            @include screen-md-max {
                flex-direction: column-reverse;
            }
            .section-left {
                position: relative;
                flex: 1.7;
                padding: 48px 20px;
                background: var(--block-contact--left-background);
                color: var(--block-contact--left-colour);
                .intro-copy-container {
                    margin-bottom: 55px;
                }
                .contacts-container {
                    display: flex;
                    flex-direction: column;
                    .dropdown-container {
                        display: flex;
                        flex-direction: column;
                    }
                    .dropdown {
                        margin-top: 30px;
                        .dropdown-title {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
            .section-right {
                position: relative;
                background: var(--block-contact--right-background);
                flex: 4;
            }
        }
        .dropdown-select {
            margin-bottom: 35px;
        }
        .contacts-block {
            margin-bottom: 65px;
            .name-title-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                .name {
                    margin-bottom: 2px;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 22px;
                }
                .title {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .phone-email-container {
                display: flex;
                flex-direction: column;
                .phone-container,
                .email-container {
                    display: flex;
                    align-items: center;
                }
                .phone,
                .email {
                    text-decoration: var(--block-contact--phone-email-text-decoration);
                    color: var(--block-contact--phone-email-colour);
                    transition: all 0.3s;
                    &:hover {
                        color: var(--neutral-1);
                    }
                }
                .icon {
                    width: 22px;
                    margin-right: 15px;
                }
            }
        }
        .contacts-block:last-child {
            margin-bottom: 0;
        }
        .contacts-block.inactive {
            display: none;
        }
    }
    .block-form {
        margin-bottom: 0;
        .block-container {
            width: 100%;
        }
    }
}