.block-fifty-fifty-content {
    position: relative;
    width: 100%;
    &.disable-scroll {
        .block-container {
            .text-container {
                .arrows,
                .arrows-background {
                    display: none;
                }
                .scroll-container {
                    overflow-y: visible;
                    height: auto;
                    padding: 0px 28px;
                    @include screen-xl-max {
                        padding: 0;
                    }
                }
            }
        }
    }
    .block-container {
        width: $screen-xl-max;
        margin: 0 auto;
        display:flex;
        padding: 0px;
        @include screen-xl-max {
            width: $mobile-container-width;
            flex-direction: column;           
            height: auto;           
        }
        &.image-right {
            flex-direction: row;
            @include screen-xl-max {
                flex-direction: column-reverse;
            }
        }
        .image-container{
            width: 600px;
            aspect-ratio: 16 / 9;
            @include screen-xl-max {
                width: 100%;                
            }
            img{
                @include screen-xl-max{
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
        .text-container{
            width: 100%;           
            position: relative;
            .scroll-container{
                overflow-y: scroll;
                padding:0px 28px 50px;        
                height: 365px;
                scroll-behavior: smooth;
                @include screen-xl-max {
                    padding: 0px;
                    height: auto;
                    overflow-y: auto;              
                }
                &::-webkit-scrollbar {
                    width: 1px;
                }
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                }
                &::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    outline: 1px solid slategrey;
                }
                .inside {
                    > * {
                        padding-bottom: 20px;
                        &:last-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
            &.left{
                direction: rtl;
                .inside{
                    direction: ltr;
                }
            }
            .arrows-background{
                position: absolute;
                bottom: 0px;
                opacity: 0.8;
                background: #fff;
                width: 99%;
                height: 40px;
                @include screen-xl-max {
                    display:none;           
                }
            }
            .arrows{               
                position: absolute;
                bottom: 0px;
                width: 100%;
                display: flex;
                justify-content: center;
                direction: ltr;
                @include screen-xl-max {
                    display:none;           
                }
                button{
                    border:none;
                    background:none;
                    transition:opacity 0.5s ease-in;
                    opacity: 1;
                    &.hidden{
                        opacity: 0;
                        visibility:hidden;
                    }
                }
                .icon-arrow-down{                    
                    width: 40px;                    
                }
                .icon-arrow-up{
                    width: 40px;
                    transform: rotate(180deg);
                    transform-origin: 50% 50%;
                }
            }
        }
    }
}