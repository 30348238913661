.block-accordion {
  position: relative;
  width: 100%;
  .block-container {
    border-top: 1px solid var(--neutral-2);
    width: $screen-xl-max;
    margin: 0 auto;
    @include screen-xl-max {
      width: $mobile-container-width;
    }
    .item {
      border-bottom: 1px solid var(--neutral-2);
      margin-bottom: 0;
      padding-bottom: 0;
      transition: padding-bottom 0.1s ease-in-out;
      cursor: pointer;
      .title-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px;
          .title {
            margin: 0;
            padding: 0;
            max-width: 90%;
          }
          .arrow-left {
            display: block;
            position: relative;
            width: 25px;
            min-width: 25px;
            height: 25px;
            transform: rotate(0deg);
            transition: transform 0.2s ease-in-out;
            path {
              fill: var(--primary-2);
            }
          }
      }
      .content-wrapper {
          max-height: 0;
          padding: 0 20px;
          overflow: hidden;
          transition: max-height 0.01s ease-in-out; // close
      }
      &.show-block {
        padding-bottom: 20px;
        .title-wrapper {
          .arrow-left {
            transform: rotate(-90deg);
          }
        }
        .content-wrapper {
          max-height: 999999px;
          transition: max-height 0.5s ease-in-out;
        }
      }
    }
  }
}