$padding: 20px;

.block-activity-pricing {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    .intro-container,
    .options-container {
        display: flex;
        justify-content: center;
        text-align: center;
        padding: $padding;
        background: var(--block-activity-pricing--options-container-background);
        color: var(--block-activity-pricing--options-container-colour);
        p {
            font-weight: 700;
            margin: 0;
            padding: 0;
        }
    }
    .options-container {
        position: relative;
        width: 100%;
        margin: 50px auto 0;
        border-bottom: 1px solid var(--block-activity-pricing--options-container-border-bottom-colour);
        @include screen-xl-max {
            margin: 30px auto 0;
        }
    }
    .title-container {
        display: flex;
        justify-content: center;
        padding: $padding;
        background: var(--block-activity-pricing--title-background);
        color: var(--block-activity-pricing--title-colour);
        .locations-wrapper {
            display: flex;
            width: $screen-md-max;
            margin: 0 auto;
            @include screen-md-max {
                width: $mobile-container-width;
                flex-direction: column;
                align-items: flex-start;
                margin: unset;
            }
            .text-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 250px;
                margin-right: 10px;
                @include screen-md-max {
                    margin-right: 0;
                    width: unset;
                    margin-bottom: 5px;
                }
                .text {
                    font-weight: 600;
                }
            }
        }
    }
    .carousel-container {
        position: relative;
        margin: 0;
        flex-grow: 1;
        @include screen-lg-max {
          width: 100%;
          margin: 0 auto;
          transform: unset;
          left: unset;
        }
        .inner-carousel {
            position: relative;
            width: $screen-xl-max;
            margin: 0 auto;
            @include screen-xl-max {
                width: 100%;
                overflow: visible;
            }
        }
        .please-select {
            position: relative;
            width: 100%;
            aspect-ratio: 16 / 9;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--block-activity-pricing--please-select-background);
            z-index: 1;
            text-align: center;
            box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
            @include screen-md-max {
                margin-top: 30px;
            }
        }
        .swiper {
            display: none;
            overflow: visible;
        }
        .activity-pricing-swiper {
            position: relative;
            width: 100%;
            padding: 15px;
            margin: 40px auto 0;
            overflow: hidden;
            @include screen-md-max {
                margin: 20px auto 0;
            }
          .swiper-wrapper {
            overflow: visible !important;
            &.stacked{
                display:flex;
                gap: 30px;
                width: 100%;
                flex-wrap: wrap;
                .swiper-slide{
                    width: 370px;
                    @include screen-md-max{
                        width: 100%;
                    }
                }
            }
            .swiper-slide {
              display: flex;
              flex-direction: column;
              height: unset !important;
              box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
              .image-container {
                position: relative;
                width: 100%;
                aspect-ratio: 16 / 9;
                overflow: hidden;
                .gradient {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background: var(--image-gradient);
                  z-index: 1;
                }
                .background-image {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: 50% 50%;
                  z-index: 0;
                }
                .text-block {
                    position: absolute;
                    bottom: $padding;
                    left: $padding;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    color: var(--block-activity-pricing--text-block-colour);
                    .title,
                    .subtitle {
                        margin: 0;
                        padding: 0;
                    }
                    .title {
                        margin-bottom: 5px;
                        font-weight: 400;
                        text-transform: uppercase;
                    }
                }
              }
              .bottom-details-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex-grow: 1;
                    padding: 0 $padding;
                    .top-details {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: $padding;
                        .detail-row {
                            display: flex;
                            flex-direction: column;
                            margin-bottom: $padding;
                            .detail-row-top {
                                justify-content: space-between;
                                display: flex;
                            }
                            p {
                                margin: 0;
                                padding: 0;
                            }
                            small {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                margin-top: -5px;
                            }
                            .row-date {
                                font-weight: 700;
                                text-transform: uppercase;
                            }
                            .row-price {
                                font-weight: 600;
                            }
                        }
                        .detail-row:last-child {
                            margin: 0;
                        }
                    }
                    .bottom-details {
                        display: flex;
                        flex-direction: column;
                    }
                    .bonus-text-container {
                        display: flex;
                        padding: $padding;
                        border-top: 1px solid var(--block-activity-pricing--bonus-text-border-top);
                        .details {
                            display: flex;
                            align-items: center;
                            .crown {
                                width: 22px;
                                height: auto;
                                margin-right: $padding;
                            }
                            .bonus-text {
                                font-weight: 600;
                            }
                        }
                    }
              }
              .cta-container {
                    border-top: 1px solid var(--block-activity-pricing--cta-container-border-top);
                    display: flex;
                    justify-content: center;
                    padding: $padding;
                }
            }
          }
        }
        .swiper-pagination {
            position: relative !important;
          }
          .swiper-pagination-bullet {
            pointer-events: all !important;
            width: 22px;
            height: 22px;
          }
          .swiper-pagination-bullet-active {
              background: var(--block-activity-pricing--swiper-pagination-bullet-active);
          }
      
          .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
            position: relative !important;
            bottom: 0 !important;
            padding: 50px 0 0 !important;
          }
      }
      .swiper-button {
        opacity: 0;
        transition: opacity 0.3s;
      }
      .swiper-button-prev.swiper-button-disabled,
      .swiper-button-next.swiper-button-disabled {
        opacity: 0 !important;
        transition: opacity 0.3s;
      }
      .swiper-button-prev,
      .swiper-button-next {
        z-index: 999999;
        @media only screen and (max-width: 1400px) {
            display: none;
        }
      }
      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: '';
        display: none;
      }
      .swiper-button-prev {
        background-image: initial;
        left: -50px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99998;
      }
      .swiper-button-next {
        background-image: initial;
        right: -50px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99998;
      }
}