.block-conditional-display {
    margin-top: 0 !important; // Needs to be important to override the > * styling which takes priority
    margin-bottom: 0 !important; // Needs to be important to override the > * styling which takes priority
    
    .condition {
        display: none;
    }
    
    .condition.active {
        display: block;
    }
}