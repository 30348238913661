$padding: 20px;

.component-header {
  // display: flex;
  // align-items: center;
  position: fixed;
  will-change: transform;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--header-background);
  z-index: 999995;
  min-height: 80px;
  transition: transform $transition-03s;
  
  @include screen-lg-max {
    min-height: auto;
  }

  &.scroll-down{
    min-height: auto;    
  }

  &.landing-page--header{
    .header-container{
      justify-content: center;
      .logo-container {
        padding: 15px;
        max-width: 270px;
      }
      img {
        width: 100%;
      }
    }
  }
  
  .mobile-navigation {
    display: none;
    flex-direction: column;
    position: absolute;
    top: -200vh;
    left: 0;
    width: 100%;
    height: var(--util-height);
    background: var(--header-background);
    z-index: -1;
    transition: top $transition-03s;
    @include screen-xl-max {
      display: flex;
    }
    .mobile-navigation-container {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background: var(--header-background);
      padding: 55px 20px 20px;
      overflow-y: scroll;
      .mobile-navigation-items {
        position: relative;
        opacity: 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 40px 0 0;
        transition: opacity $transition-03s;
        transition-delay: $transition-03s;
        .primary-button {
          display: block;
          width: 100%;
          text-align: center;
          font-size: 20px;
          line-height: 20px;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
          background: var(--header-button-items-background);
          color: var(--header-button-items-text-colour);
          text-decoration: none;
          padding: 11px 25px;
          border-radius: 30px;
          margin-bottom: 8px;
        }
        .has-button-items {
          display:none;
          .primary-button {
            @include screen-lg-max {
              display: none;
            }
          }
        }
        .sub-menu {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: $padding;
          &.primary-navigation-languages {
            display: flex;
            flex-direction: row;
            position: relative;
            margin-bottom: 8px;
            z-index: 999996;
            @include screen-lg-max {
              display: none;
              visibility: hidden;
            }
            .category {
              position: relative;
              width: 42px;
              height: 42px;
              border-radius: 50%;
              overflow: hidden;
              img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
              }
            }
          }
          .category {
            display: flex;
            flex-direction: column;
            color: var(--header-colour);
            text-decoration: none;
            text-transform: uppercase;
            font-size: var(--header-navigation-category-font-size);
            font-weight: var(--header-navigation-category-font-weight);
            line-height: 20px;
            .level-1-navigation-name {
              font-weight: 400;
            }
          }
          .category-items {
            display: flex;
            flex-direction: column;
            .bullet-icon {
              width: var(--header-category-item-bullet-width);
              margin-right: 15px;
            }
            .item {
              display: flex;
              align-items: center;
              color: var(--header-colour);
              text-decoration: none;
              text-transform: uppercase;
              font-weight: 400;
              margin-top: $padding;
              h4 {
                font-weight: 400;
              }
              .text {
                font-weight: var(--header-category-item-font-weight);
                font-size: var(--header-navigation-category-font-size);
              }
            }
            .item:last-child {
              margin-bottom: 0;
            }
          }
        }
        .sub-menu:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    background: var(--header-background);
    align-items: center;
    width: $screen-xl-max;
    margin: 0 auto;
    transition: padding $transition-03s;
    z-index: 10;
    @include screen-xl-max {
      width: 100%;
    }
    .header-container-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: $screen-xl-max;
      margin: 0 auto;
      padding: 15px 0;
      transition: padding $transition-03s;
      @include screen-xl-max {
        padding: 15px 0;
        width: $mobile-container-width;
      }
      .divider {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 1px;
        background: var(--header-divider-background);
        transition: width $transition-03s;
        transition-delay: 0.3s;
        @include screen-md-max {
          display: block;
        }
      }
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .logo-container {
          width: 218px;
          transition: width $transition-03s;
          @include screen-xl-max {
            width: var(--header-logo-container-width);
          }
          .logo {
            width: 100%;
          }
        }
        .navigation-container {
          display: flex;
          align-items: center;
          .primary-navigation-languages {
            @include screen-lg-max {
              display: flex;
              flex-direction: row;
              visibility: visible;
              position: relative;
              z-index: 999996;
              margin-right: 16px;
            }
            .category {
              position: relative;
              width: 42px;
              height: 42px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 10px;
              img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
              }
            }
          }
          .open-close-container {
            position: relative;
            display: none;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            @include screen-xl-max {
              display: flex;
            }
            .icon-hamburger {
              display: none;
              width: 20px;
              height: auto;
              cursor: pointer;
              @include screen-xl-max {
                display: block;
              }
            }
            .icon-mobile-navigation-close {
              position: absolute;
              display: none;
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }
          .open-close-container.active {
            .icon-hamburger {
              display: none;
            }
            .icon-mobile-navigation-close {
              display: block;
            }
          }
          .sub-menu-list {
            display: flex;
            align-items: center;
            @include screen-xl-max {
              display: none;
            }
            .sub-menu {
              position: relative;
              display: flex;
              flex-direction: column;
              color: var(--header-colour);
              margin-right: 20px;
              margin-top: 10px;
              &.primary-navigation-languages {
                display: flex;
                flex-direction: row;
                visibility: visible;
                position: relative;
                z-index: 999996;
                .category {
                  position: relative;
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                  overflow: hidden;
                  img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                  }
                }
              }
              .category {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                color: var(--header-colour);
                text-decoration: none;
                text-transform: uppercase;
                cursor: pointer;
                text-decoration: underline var(--header-category-item-hover-weight) rgba(0, 0, 0, 0);
                text-underline-offset: 0em;
                transition: text-decoration-color 300ms, text-underline-offset 500ms;
                font-weight: var(--header-navigation-category-font-weight);
              }
              .category:hover {
                text-decoration-color: var(--header-category-hover-colour);
                text-underline-offset: 0.3em;
              }
              .category-items {
                position: absolute;
                transform: scaleY(0);
                background: var(--header-background);
                top: 25px;
                left: -20px;
                transition: height $transition-03s;
                .bullet-icon {
                  display: none;
                }
                .item {
                  display: block;
                  position: relative;
                  padding: 15px 20px 0 20px;
                  min-width: 200px;
                  color: var(--header-colour);
                  text-decoration: none;
                  font-size: 20px;
                  text-transform: uppercase;
                  cursor: pointer;
                  text-decoration: underline var(--header-category-item-hover-weight) rgba(0, 0, 0, 0);
                  text-underline-offset: 0em;
                  transition: text-decoration-color 300ms, text-underline-offset 500ms;
                  h5 {
                    font-weight: var(--header-category-item-font-weight);
                    line-height: 1.6;
                  }
                  svg {
                    display: none;
                  }
                  @include screen-md-max {
                    font-weight: 400;
                  }
                }
                .item:hover {
                  text-decoration-color: var(--header-category-item-hover-colour);
                  text-underline-offset: 0.3em;
                }
                .item:last-child {
                  padding: 15px 20px;
                }
              }
            }
            .button-menu {
              position: relative;
              display: flex;
              flex-direction: column;
              //color: var(--header-colour);
              margin-right: 20px;
              margin-top: 10px;

              .desktop-label {
                display: block;
                margin: 0;
                text-align: center;
                @include screen-lg-max {
                  display: none;
                  visibility: hidden;
                }
              }
              .mobile-label {
                display: none;
                visibility: hidden;
                text-align: center;
                @include screen-lg-max {
                  display: block;
                  visibility: visible;
                }
              }
              
              .button {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                color: var(--header-colour);
                text-decoration: none;
                text-transform: uppercase;
                cursor: pointer;
                text-decoration: underline var(--header-category-item-hover-weight) rgba(0, 0, 0, 0);
                text-underline-offset: 0em;
                transition: text-decoration-color 300ms, text-underline-offset 500ms;
                z-index: 1;
                .btn-sm:hover {
                    text-decoration-color: var(--button-black-hover-background);
                }
              }
              .btn {
                border-bottom: 0px;
                background: var(--primary-2);
                color: var(--neutral-white);
                font-size: 20px;
                line-height: 20px;
                padding: 11px 25px;
                box-shadow: var(--button-small-box-shadow);
              }
              
              .has-button-items {
                
                background: none;
                border: 0px;
                padding: 0;
                .btn {
                  box-shadow: initial;
                  cursor: default;
                }
              }
              .button-items {
                box-shadow: var(--button-small-box-shadow);
              }
              
              .button:hover {
                text-underline-offset: 0.1em;
              }
              .button-items {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;
                position: absolute;
                align-items: center;
                transform: scaleY(0);
                background: var(--primary-2);
                top: 0px;
                left: 0px;
                width: 100%;
                transition: height $transition-03s;
                margin-top: 20px;
                padding-top: 12px;
                border-bottom-left-radius: 30px;
                border-bottom-right-radius: 30px;
                .item {
                  display: block;
                  position: relative;
                  padding: 15px 20px 0 20px;
                  min-width: 200px;
                  color: var(--header-colour);
                  text-decoration: none;
                  font-size: 20px;
                  text-transform: uppercase;
                  cursor: pointer;
                  text-decoration: underline var(--header-category-item-hover-weight) var(--header-category-item-hover-colour);
                  text-underline-offset: 0em;
                  transition: text-decoration-color 300ms, text-underline-offset 500ms;
                  text-align: center;
                  font-weight: 700;
                  svg {
                    display: none;
                  }
                  @include screen-md-max {
                    font-weight: 400;
                  }
                }
                .item:hover {
                  text-decoration-color: var(--header-category-item-hover-colour);
                  text-underline-offset: 0.1em;
                }
                .item:last-child {
                  padding: 15px 20px;
                }
              }
            }
            .btn-sm {
                font-weight: 500;
            }
            .sub-menu:hover .category-items {
              animation: categoryItemsShow 250ms ease-in-out forwards;
              transform-origin: top center;
            }
            .button-menu:hover .button-items {
              animation: categoryItemsShow 250ms ease-in-out forwards;
              transform-origin: top center;
              .item:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .btn-sm {
            font-weight: 500;
        }
        .sub-menu:hover .category-items {
          animation: categoryItemsShow 250ms ease-in-out forwards;
          transform-origin: top center;
        }
        .button-menu:hover .button-items {
            animation: categoryItemsShow 250ms ease-in-out forwards;
            transform-origin: top center;
            .item:hover {
              text-decoration: underline;
            }
        }
      }
    }
  }
}
.component-header .mobile-navigation.active {
  top: 0vh;
  .mobile-navigation-items {
    opacity: 1;
    .desktop-label {
      display: block;
      margin: 0;
      @include screen-lg-max {
        display: none;
        visibility: hidden;
      }
    }
    .button{
      text-decoration: none;
    }
    .mobile-label {
      display: none;
      visibility: hidden;
      @include screen-lg-max {
        display: block;
        visibility: visible;
      }
    }
    .button-items .item {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 20px;
      line-height: 20px;
      padding: 11px 25px;
      box-shadow: 2px 2px 5px rgba(0,0,0,.2);
      background: var(--header-button-items-background);
      color: var(--header-button-items-text-colour);
      text-decoration: none;
      padding: 11px 25px;
      border-radius: 30px;
      margin-bottom: 8px;
      &:last-child {
          margin-bottom: 0;
      }
      h5 {
          font-size: 20px;
          line-height: 20px;
          font-weight: 700;
          text-transform: uppercase;
      }
  }
  }
}

.component-header.mobile-active {
  .header-container {
    .divider {
      width: 100%;
    }
  }
}

.component-header.scroll-down.mobile-active {
  .header-container {
    padding: 0;
  }
}

@keyframes categoryItemsShow {
  0% {
      transform: scaleY(0)
  }
  80% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
}