$intro-package-padding: 20px;

.block-intro-package-tile {
  position: relative;
  width: 100%;
  .block-container {
    display: flex;
    align-items: flex-start;
    width: $screen-xl-max;
    margin: 0 auto;
    @include screen-xl-max {
      width: $mobile-container-width;
    }
    @include screen-md-max {
      flex-direction: var(--block-intro-package--container-flex-direction-mobile);
    }
    .body-container {
      flex: 2;
      @include screen-xl-max {
        flex: 1.5;
      }
      @include screen-lg-max {
        flex: 1;
      }
      @include screen-md-max {
        margin-bottom: var(--block-intro-package--body-container-margin-bottom);
      }
    }
    .package-tile-container {
      width: 435px;
      margin-left: 80px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      @include screen-md-max {
        width: 100%;
        margin-left: 0px;
        margin-bottom: var(--block-intro-package--package-tile-container-margin-bottom);
      }
      .image-container {
        position: relative;
        width: 100%;
        max-height: 217px;
        height: 217px;
        .background-image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
        .gradient {
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--image-gradient);
          z-index: 1;
        }
        .title-container {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: $intro-package-padding;
          left: $intro-package-padding;
          color: var(--block-intro-package--title-colour);
          margin: 0;
          z-index: 2;
          .title {
            margin: 0;
            padding: 0;
            text-transform: var(--block-intro-package--title-text-transform);
            font-weight: var(--block-intro-package--title-font-weight);
            &.hidden{
              visibility: hidden;
            }
          }
          .icon-info {
            position: relative;
            margin-left: $intro-package-padding;
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
      }
      .details-container {
        padding: $intro-package-padding;
        .details-top-container {
          display: flex;
          flex-direction: column;
          margin-bottom: $intro-package-padding;
          .detail-row {
            display: flex;
            align-items: center;
            margin-bottom: calc($intro-package-padding / 2);
            .icon {
              width: 22px;
              height: auto;
              margin-right: calc($intro-package-padding / 2);
            }
          }
        }
        .cta-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid var(--block-intro-package--cta-container-border-top);
          padding: $intro-package-padding 0 0;
          .price {
            text-transform: lowercase;
            font-weight: 700;
            margin: 0;
            padding: 0;
          }
        }
      }
      .details-container.modal-active {
        .deal-modal {
          display: block;
        }
      }
      button {
        align-self: flex-start;
      }
      .deal-modal {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 999999;
        .deal-modal-details {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: var(--block-intro-package--deals-modal-background);
          color: var(--block-intro-package--deals-modal-colour);
          width: 628px;
          z-index: 5;
          @include screen-md-max {
            width: $mobile-container-width;
          }
          .details-container {
            display: flex;
            flex-direction: column;
            padding: $intro-package-padding;
          }
        }
        .icon-modal-close {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 35px;
          height: 35px;
          z-index: 1;
          cursor: pointer;
        }
      }
    }
  }
}