.block-menu-single {
    position: relative;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    .block-container {
      width: $screen-xl-max;
      @include screen-xl-max {
        width: $mobile-container-width;
      }
      .menu-heading {
        text-transform: uppercase;
      }
      .description-container{
        margin:0 auto;
        text-align: center;
        padding: 0 0 50px;
        width: 768px;
        @include screen-xl-max {
          width: 100%;
          margin: 0;
          padding: 0 0 $padding;
          text-align: left;
        }
        @include screen-md-max {
          padding: 0 0 30px;
        }
        .external-pdf{
          display:var(--block-menu-single--top-external-pdf-display);
        }
      }
      .bottom{
        padding:40px 0 0;
        text-align:center;
        @include screen-xl-max {
          text-align: left;
        }
      }
      .external-pdf{
        a{
          color: var(--hyperlink);
          font-weight: 400;
  
          @include screen-md-max{
            color:var(--block-menu-single--external-pdf-mobile-text-color);
          }
        }
      }
      .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: var(--block-food-drinks--menu-title-font-size);
          line-height: var(--block-food-drinks--menu-title-line-height);
          font-weight: 700;
          text-transform:  var(--block-menu-single--title-text-transform);
          margin: 0;
          @include screen-md-max {
            font-size: var(--block-food-drinks--menu-title-mobile-font-size);
            line-height: var(--block-food-drinks--menu-title-mobile-line-height);
          }
        }
        .title-arrows-container {
          display: none;
          position: relative;
          width: 22px;
          height: 22px;
          @include screen-md-max {
            display: flex;
          }
          .arrow-left {
            position: absolute;
            width: 100%;
            height: 100%;
            will-change: transform;
            transition: transform $transition-03s;
          }
        }
      }
      .columns-container {
        display: flex;
        flex: 1;
        @include screen-md-max {
          border-bottom: 1px solid var(--block-menu-single--border);
        }
        @include screen-xl-max {
          margin: 0;
        }
        @include screen-md-max {
          flex-direction: column;
        }
        .column {
          flex: 1;
          margin-right: 20px;
          border-top: 1px solid var(--block-menu-single--border);
          @include screen-md-max {
            margin: 0;
          }
        }
        .column-right {
          @include screen-md-max {
            .block-menu-section:last-child {
              border-bottom: none;
            }
          }
        }
        .column:last-child {
          margin: 0;
          @include screen-md-max {
            border-top: none;
          }
        }
        .category-description {
          margin-bottom: 35px;
          @include screen-md-max {
            margin-bottom: 22px;
          }
        }
        .item-rows {
          display: flex;
          flex-direction: column;
          margin-top: 5px;
          @include screen-md-max {
            display: none;
          }
          .item-row {
            display: flex;
            justify-content: space-between;
            align-items: top;
            margin-bottom: 10px;
            .item-description {
              display: flex;
              flex-direction: column;
              margin-right: 10px;
              .item-title-container {
                img {
                  margin-left: 5px;
                }
              }
              .name {
                display: inline;
                font-weight: 700;
                font-size: var(--block-menu-single--name-font-size);
                line-height: var(--block-menu-single--name-line-height);
                margin: 0;
                padding: 0;
                @include screen-md-max {
                  font-size: var(--block-menu-single--name-font-size-mobile);
                  line-height: var(--block-menu-single--name-line-height-mobile);
                }
              }
              .extra {
                max-width: 400px;
              }
            }
            .item-price {
              text-align: right;
              min-width: 100px;
              .price {
                font-weight: 700;
                font-size: var(--block-menu-single--name-font-size);
                line-height: var(--block-menu-single--name-line-height);
                text-align: right;
                @include screen-md-max {
                  font-size: var(--block-menu-single--name-font-size-mobile);
                  line-height: var(--block-menu-single--name-line-height-mobile);
                }
              }
            }
          }
          .item-row:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .block-menu-section.show {
      display: block;
      animation: fade-in $transition-03s;
    }
    .block-menu-section.show-accordion {
      @include screen-md-max {
        .title-container {
          .title{
            color:var(--block-menu-single--accordion-title-mobile-color);
          }
          .title-arrows-container {
            .arrow-left {
              transform: rotate(-90deg);
            }
          }
        }
        .item-rows {
          display: flex;
        }
      }
    }
  }
  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  @keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
  }