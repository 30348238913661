.block-full-width-image-carousel {
  display: flex;
  flex-direction: column;
  position: relative;
  @include screen-md-max {
    height: unset;
  }
  &.single-item{
    @include screen-md-max {
      width: $mobile-container-width;
    }
  }
  .block-container {
    position: relative;
    width: $screen-xl-max;    
    margin: 0 auto;
    padding: 0px;
    @include screen-xl-max {
        width: $mobile-container-width;
    }
    .swiper {
      position: relative;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      @include screen-xl-max {
        width: 100%;
      }
      .swiper-wrapper {
        overflow: visible !important;
        .swiper-slide {
          display: flex;
          flex-direction: column;          
          height: unset !important;        
          overflow: hidden;
          .image-container {
            position: relative;
            width: 100%;
            overflow: hidden;
            background: var(--block-gallery--image-background);
            @include screen-md-max{
              height: auto;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 50%;
              @include screen-md-max{
                height:auto;
              }
            }
          }
          .cta-container{
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 40px;
            @include screen-md-max{
              bottom: 20px;
            }
            .cta{              
              text-decoration: none;  
              display: inline-block;
              @include screen-md-max{
                font-size: 16px;
              }
            }
          } 
        }
      }
    }
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 999999;
    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: '';
    display: none;
  }
  .swiper-button-prev {
    background-image: initial;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99998;
  }
  .swiper-button-next {
    background-image: initial;
    right: -50px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99998;
  }
  .swiper-pagination {
    position: relative !important;
    padding: 50px 0;
    @include screen-md-max{
      padding: 30px 0px !important;
    }
  }
  .swiper-pagination-bullet {
    pointer-events: all !important;
    width: 22px;
    height: 22px;
  }
  .swiper-pagination-bullet-active {
    background: var(--block-gallery--swiper-pagination-bullet-active);
  }
}