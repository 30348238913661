.locations-venue {
    display: flex;
    position: relative;
    width: 100%;
    .block-container {
        display: flex;
        width: $screen-xl-max;
        margin: 0 auto;
        position: relative;
        @include screen-xl-max {
            width: $mobile-container-width;
        }
        @include screen-md-max {
            flex-direction: column;
        }
        .details-column {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-right: 20px;
            @include screen-md-max {
                margin: 0;
                margin-bottom: 35px;
            }
            p {
                margin: 0;
                padding: 0;
            }
            .heading-container {
                display: flex;
                align-items: center;
                margin-bottom: 35px;
                .icon {
                    width: 25px;
                    height: 25px;
                    margin-right: 15px;
                }
                .heading {
                    font-weight: 700;
                    text-transform: var(--block-locations-venue--heading-text-transform);

                    @include screen-md-max {
                        text-transform: uppercase;
                    }
                }
            }
            .details-container {
                display: flex;
                flex-direction: column;
            }
            .activities-container {
                margin-top: 35px;
                .activity-icon {                  
                    display:inline;
                    position:relative;
                    cursor: pointer;
                    .tooltip {
                      visibility: hidden;
                      position:absolute;
                      background-color: var(--block-locations--tooltip-bg-color);
                      color: var(--block-locations--tooltip-text-color);
                      text-align:center;
                      padding: 5px 20px;           
                      top:100%;
                      left: 50%;     
                      width:120px;
                      margin-left: -60px;    
                      z-index: 100;
                      margin-top: 10px;
                    }
                    &:hover .tooltip {
                      visibility: visible;
                    }
                  }
            }
        }
        .details-column:last-child {
            margin-right: 0;
        }
        .details-column.location {
            .location-link {
                margin-top: 30px;
            }
        }
    }
}