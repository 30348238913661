@mixin screen-md-max {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin screen-lg-max {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin screen-xl-max {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin screen-lg-min {
  @media (min-width: #{$screen-lg-max}) {
    @content;
  }
}