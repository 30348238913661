.block-google-reviews {
  position: relative;
  .block-container {
    width: $screen-xl-max;
    margin: 0 auto;
    @include screen-xl-max {
      width: $mobile-container-width;
    }
    .title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        font-weight: 700;
        margin-bottom: 50px;
        text-align: center;
        @include screen-md-max {
          margin-bottom: 30px;
        }
      }
    }
  }
}