.block-icons {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    flex-grow: 1;
    &--body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            @include screen-md-max{
                width: 90%;
                padding: 0px;
            }
            .heading {
                margin-bottom: 50px;   
                @include screen-md-max{
                    margin-bottom: 34px;   
                    text-align: center;                   
                }            
            }
            .links {
                margin-bottom: 0;
            }
            ul {
                display:flex;
                width: 782px;
                padding-left:0px;
                list-style-type: none;
                flex-wrap: wrap;
                justify-content: center;
                gap: 50px;
                @include screen-md-max{
                    width: 100%;                    
                    gap: 50px;
                }
                li {
                    &:hover {
                        .item-image {
                            transform: scale(1.2);
                        }
                    }         
                    a {
                        color: var(--block-block-icons--link-color);
                        text-decoration: none;
                        display:flex;
                        flex-direction: column;
                        align-items: center;    
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 700;
                        @include screen-md-max {
                            font-size: 18px;
                            line-height: 30px;
                        }
                        .item-image {
                            height: 145px;
                            width: 145px;
                            transform: scale(1);
                            transform-origin: 50% 50%;
                            transition: 0.3s transform;
                            @include screen-md-max {
                                width: 118px;
                                height: 118px;
                            }
                        }
                        .item-text {
                            margin-top: 30px;
                            @include screen-md-max {
                                margin-top: 24px;
                            }
                        }
                    } 
                }
            }
        }
    }
}