.trading-hours {
  font-size: 16px;
  line-height: 25px;
  
  .skeleton-row {
    
    display: block;
    height: 18px;
    min-width: 100%;
    margin: 6px 0;
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    background-size: 600px;
    animation: skeleton-shine 2s infinite linear;
  }
  
  .trading-row {
    display: flex;
    justify-content: space-between;
    
    .date,
    .opening-hours {
      text-align: left;
      width: 50%;
    }

    .date {
      font-weight: bold;
    }
    
    .opening-hours {
      text-wrap: nowrap;
    }
  }  
}

@include media-breakpoint-up(md) {
  .trading-hours {
    font-size: 18px;
    line-height: 32px;

    .skeleton-row {
      display: block;
      height: 25px;
      min-width: 100%;
      margin: 6px 0;
    }
  }
}

@keyframes skeleton-shine {
  0% {
    background-position: -100px;
  }

  40%, 100% {
    background-position: 500px;
  }
}