$padding: 25px;

.package-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--package-tile--background);
  width: calc(33% - 16px);
  box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
  @include screen-xl-max {
    width: calc(50% - 16px);
  }
  @include screen-md-max {
    width: 100%;
  }
  .top {
    position: relative;
    display: flex;
    flex-direction: column;
    .header {
      position: relative;
      display: inline-block;
      background: var(--package-tile--header-background);
      width: 100%;
      .gradient {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: var(--image-gradient);
        z-index: 1;
      }
      .background-image {
        position: relative;
        width: 100%;
        height: auto;
      }
      .heading-container {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: $padding;
        left: $padding;
        z-index: 2;
        .heading {
          color: var(--package-tile--heading-colour);
          margin: 0;
          padding: 0;
          margin-right: 10px;
          text-transform: var(--block-package--heading-text-transform);
          font-weight: var(--block-package--heading-font-weight);
        }
        .icon-info {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
    }
  }
  .bottom {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding: $padding;
    .features-list {
      padding-bottom: $padding;
      ul {
        padding: 0;
        margin: 0;
        margin-bottom: var(--package-tile--ul-margin-bottom-desktop);
        font-weight: 600;
        list-style-type: none;
        @include screen-md-max {
          margin-bottom: var(--package-tile--ul-margin-bottom-mobile);
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      li {
        display: flex;
        font-weight: 400;
        margin-bottom: 15px;
      }
      li:last-child {
        margin-bottom: 0;
      }
      img {
        margin-right: 15px;
      }
    }
    .button-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: $padding;
      border-top: 1px solid var(--package-tile--button-border-top);
      .per-person {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        .from {
          font-weight: 700;
          margin-bottom: 0px;
        }
        .price {
          font-weight: 800;
          text-transform: lowercase;
        }
      }
      
    }
  }
}
