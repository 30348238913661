@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

$fontPrimary: 'Montserrat', sans-serif;
$fontSecondary: 'Montserrat', sans-serif;
$browser-context: 16px;
$body: (
        "fontSize": 16px,
        "lineHeight": 24px,
        "fontWeight": 300
);

$h1: (
        "fontSize": 60px,
        "lineHeight": 60px
);

$h1Mobile: (
        "fontSize": 40px,
        "lineHeight": 40px
);

$h2: (
        "fontSize": 40px,
        "lineHeight": 40px
);

$h2Mobile: (
        "fontSize": 30px,
        "lineHeight": 30px
);

$h3: (
        "fontSize": 25px,
        "lineHeight": 30px
);

$h4: (
        "fontSize": 20px,
        "lineHeight": 20px
);

$h5: (
        "fontSize": 16px,
        "lineHeight": 18px
);

$p: (
        "fontSize": 18px,
        "lineHeight": 32px
);

$pMobile: (
        "fontSize": 16px,
        "lineHeight": 25px
);