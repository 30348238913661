$padding: 20px;

.block-activity-details {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  .title-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    .title {
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .carousel-container {
    position: relative;
    margin: 0;
    margin-top: 20px;
    flex-grow: 1;
    @include screen-lg-max {
      width: 100%;
      margin: 20px auto;
      transform: unset;
      left: unset;
    }
    .inner-carousel {
      position: relative;
      width: $screen-xl-max;
      margin: 0 auto;
      @include screen-xl-max {
        width: 100%;
        overflow: visible;
      }
    }
    .activity-details-swiper {
      position: relative;
      width: 100%;
      padding: 15px;
      margin: 50px auto 0;
      @include screen-xl-max {
        width: 100%;
      }
      .swiper-wrapper {
        overflow: visible !important;
        &.stacked{
          display:flex;
          gap: 30px;
          width: 100%;
          flex-wrap: wrap;
          .swiper-slide {
            width: 370px;
            @include screen-md-max{
                width: 100%;
            }
          }
        }
        .swiper-slide {
          display: flex;
          flex-direction: column;
          height: unset !important;
          overflow: hidden;
          box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
          .image-container {
            position: relative;
            width: 100%;
            aspect-ratio: 16 / 9;
            overflow: hidden;
            .background-image {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .heading {
              position: absolute;
              bottom: $padding;
              left: $padding;
              color: var(--block-activity-details--heading-colour);
              margin: 0;
              z-index: 2;
              text-transform: var(--block-activity-details-heading-text-transform);
              font-weight: 400;
            }
            .gradient {
              position: absolute;
              width: 100%;
              height: 100%;
              background: var(--image-gradient);
              z-index: 1;
            }
          }
          .details-container {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: $padding 15px;
            .icons-list-container {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: $padding;
              .icon-block {
                display: flex;
                align-items: center;
                flex-basis: 50%;
                margin-bottom: 5px;
              }
              .icon-block:last-child {
                margin: 0;
              }
              .icon {
                margin-right: 10px;
              }
              .content {
                font-weight: 400;
                line-height: 28px;
              }
            }
            .age-difficulty-container {
              display: flex;
              flex-direction: column;
              margin-bottom: $padding;
              .age-difficulty-row {
                display: flex;
                margin-bottom: 5px;
                p {
                  margin: 0;
                  padding: 0;
                }
                .heading {
                  font-weight: 700;
                  flex: 1.2;
                  text-transform: uppercase;
                }
                .content {
                  flex: 2;
                }
              }
              .age-difficulty-row:last-child {
                margin: 0;
              }
            }
            .description-container {
              display: flex;
              flex-grow: 1;
              margin-bottom: $padding;
              p {
                display: -webkit-box;
                overflow: hidden;
                line-height: 28px;
              }
            }
            .cta-container {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  .swiper-pagination {
    position: relative !important;
    padding: 50px 0 0 !important;
  }
  .swiper-pagination-bullet {
    pointer-events: all !important;
    width: 22px;
    height: 22px;
  }
  .swiper-pagination-bullet-active {
    background: var(--block-activity-details--swiper-pagination-bullet-active);
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 999999;
    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: '';
    display: none;
  }
  .swiper-button-prev {
    background-image: initial;
    left: -50px;
    top: 45%;
    transform: translateY(-50%);
    z-index: 99998;
  }
  .swiper-button-next {
    background-image: initial;
    right: -50px;
    top: 45%;
    transform: translateY(-50%);
    z-index: 99998;
  }
}
  