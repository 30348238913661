$faq-padding: 25px;

.block-faq {
  position: relative;
  width: 100%;
  margin: 0 auto;
  &.accordion {
    .section {
      .section-bottom {
        margin: 50px auto 0;
        @include screen-md-max {
          margin: 30px auto 0;
        }
        .button-back-top {
          margin: 20px 20px 0 0;
        }
      }
    }
  }
  &.expanded {
    .faq-section-container {
      .questions {
        .faq-item {
          .answer-wrapper {
            margin-top: 15px;
          }
        }
      }
    }
  }
  .section {
    width: 100%;
    .section-top {
      .section-top-anchor {
        display: block;
        position: relative;
        top: -70px;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      background: var(--block-faq--section-top-background);
      color: var(--block-faq--section-top-colour);
      padding: $faq-padding;
      .heading-container {
        margin-bottom: $faq-padding;
        .heading {
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      .jump-to-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        @include screen-md-max {
          flex-direction: column;
          text-align: var(--block-faq-section--jump-container-text-align);
        }
        .jump-text {
          margin-right: 5px;

          &-colon{
            @include screen-md-max{
              display: var(--block-faq-section--jump-text-colon-mobile-display);
            }
          }
        }
        .jump-categories {
          display: flex;
          max-width: var(--block-faq-section--jump-categories-max-width);
          flex-wrap: wrap;
          justify-content: var(--block-faq-section--jump-categories-justify-content);
          .category {
            margin: 0 3px;
            text-transform: var(--block-faq-section--category-text-transform);
            font-weight: 600;
            color: var(--block-faq-section--category-color);

            &:hover{
              color:var(--block-faq-section--category-hover-color);
            }
          }
        }
      }
    }
    .button-back-top{
      @include screen-md-max{
        color: var(--block-faq-section--back-to-top-mobile-color);
      }
    }
    .section-bottom {
      width: $screen-xl-max;
      margin: 0 auto;
      background: var(--block-faq--section-bottom-background);
      @include screen-xl-max {
        width: $mobile-container-width;
      }
    }
  }
}