$faq-section-padding: 25px;

.faq-section {
  position: relative;
  display: flex;
  border-bottom: 1px solid var(--section-faq--border-bottom);
  padding: calc($faq-section-padding * 2) 0;
  &.accordion {
    padding: 20px 0;
    .faq-section-container {
      @include screen-xl-max {
        flex-direction: column;
      }
      .category {
        @include screen-xl-max {
          text-align: center;
          margin-bottom: 30px;
        }
      }
      .questions {
        border-top: 1px solid var(--neutral-2);
        .faq-item {
          border-bottom: 1px solid var(--neutral-2);
          margin-bottom: 0;
          padding-bottom: 0;
          transition: padding-bottom 0.1s ease-in-out;
          cursor: pointer;
          .question-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            .question {
              margin-bottom: 0;
              max-width: 90%;
              font-weight: 700;
            }
            .arrow-left {
              display: block;
              position: relative;
              width: 25px;
              min-width: 25px;
              height: 25px;
              transform: rotate(0deg);
              transition: transform 0.2s ease-in-out;
              path {
                fill: var(--primary-2);
              }
            }
          }
          .answer-wrapper {
            max-height: 0;
            padding: 0 20px;
            overflow: hidden;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); // close
          }
          &.show-block {
            padding-bottom: 20px;
            .question-wrapper {
              .arrow-left {
                transform: rotate(-90deg);
              }
            }
            .answer-wrapper {
              max-height: 999999px;
              transition: max-height 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
  @include screen-xl-max {
    flex-direction: column;
  }
  .faq-section-container {
    position: relative;
    display: flex;
    width: 100%;
    @include screen-md-max {
      flex-direction: column;
    }
    .faq-section-anchor {
      display: block;
      position: relative;
      top: -85px;
    }
    .category {
      flex: 1.5;
      @include screen-xl-max {
        margin-bottom: $faq-section-padding;
      }
      .name {
        font-weight: 700;
        text-transform: capitalize;
      }
    }
    .questions {
      display: flex;
      flex: 3;
      flex-direction: column;
      .question-wrapper {
        .arrow-left {
          display: none;
        }
      }
      .button-back-top {
        align-self: flex-end;
        font-weight: 600;
      }
    }
  }
}
.faq-section:last-child {
  border: none;
}