.block-video-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 760px;
  overflow: hidden;
  background: var(--block-video--background);
  pointer-events: none;
  margin-top: 0;
  margin-bottom: 0;
  
  .loading-container {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;

    &.active {
      opacity: 1;
    }
    
    
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);  
    }
  }
  
  @include screen-xl-max {
    height: unset;
    aspect-ratio: 16 / 9;
  }
  @include screen-md-max {
    pointer-events: all;
    aspect-ratio: unset;
  }
  .video-container {
    background: var(--block-video--background);
    width: 100%;
    height: 100%;

    .video-wrapper {
      opacity: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.5s;

      &.active {
        opacity: 1;
      }
    }
  }
  
  
  .video-heading {
    position: absolute;
    top: -500%;
    left: -500%;
    z-index: -999;
    opacity: 0;
  }
  .plyr {
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .plyr__video-embed {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mobile-image{
    display:none;
    width:100%;    
  }
  &.has-mobile-image{
    @include screen-md-max{
      .video-container{
        display:none;
      }

      .mobile-image{
        display:block;
      }
    }
  }
  .plyr--full-ui.plyr--video .plyr__control--overlaid {
    background: rgba(255, 255, 255, 0.5);
  }
  .plyr__control--overlaid {
    transform: scale(2);
    transform-origin: 100% 100%;
  }
  .plyr--playing,
  .plyr--stopped {
    opacity: 1;
    transition: opacity ease-in-out 0.5s;
  }
  .plyr__video-wrapper {
    height: 100%;
  }
  .plyr__video-wrapper iframe {
    width: 100%;
    height: 100%;
  }
  .plyr__controls {
    display: none !important;
  }
}