.block-cta-list {
  display: flex;
  position: relative;
  .block-container {
    display: flex;
    flex-flow: row wrap;
    gap: 25px;
    .cta-list-item {
      display: flex;
      flex-direction: column;
      background: var(--block-cta-list--item-background);
      width: calc(33.33% - 17px);
      box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
      @include screen-xl-max {
        width: calc(50% - 13px);
      }
      @include screen-md-max {
        width: 100%;
      }
      .header {
        position: relative;
        display: inline-block;
        width: 100%;
        aspect-ratio: 4 / 2;
        overflow: hidden;
        .heading {
          position: absolute;
          bottom: 20px;
          left: 20px;
          color: var(--block-cta-list--heading-colour);
          margin: 0;
          font-weight: 400;
          text-transform: uppercase;
          z-index: 2;
        }
        .background-image-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 0;
          transition: $transition-03s;
          .background-image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            z-index: 0;
          }
        }
        .gradient {
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--image-gradient);
          z-index: 1;
        }
      }
      .body {
        padding: 30px 20px 15px;
        flex-grow: 1;
        font-weight: 400;
      }
      .button-container {
        position: relative;
        display: flex;
        padding: 15px 20px 30px;
        align-items: center;
        @include screen-md-max {
          flex-direction: column-reverse;
          justify-content: center;
        }
        .btn-primary-button {
          justify-self: right;
          text-align: center;
        }
        .more-info {
          text-decoration: none;
          text-transform: uppercase;
          @include screen-md-max {
            margin-top: 15px;
          }
        }
    
        span.btn-sm{
          display:block;
        }
        
      }
    }
    
    .cta-list-item:hover {
      .background-image-container {
        transform: scale(1.3);
      }
    }
  }
}