﻿.block-full-height-image {
  display: block;
  position: relative;
  height: 100vh;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  .caption {
    display: block;
    position: absolute;
    top: 50%;
    left: 10%;
    right: 10%;
    transform: translateY(-50%);
  }
  .text {
    margin-bottom: 40px;
    color: var(--neutral-white);
  }
  a.btn {
    display: inline-block;
    text-decoration: none;
  }
}

.app-body-container > *:first-child.block-full-height-image {
  margin-bottom: 0;
  margin-top: calc(0px - var(--app-body-container--padding-top-screen-md-max));
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
  .block-full-height-image {
    .caption {
      left: 40%;
    }
  }
  .app-body-container > *:first-child.block-full-height-image {
    margin-top: calc(0px - var(--app-body-container--padding-top-screen-md-max));
  }
}

@include media-breakpoint-up(lg) {
  .block-full-height-image {
    .caption {
      left: 50%;
    }
  }
  .app-body-container > *:first-child.block-full-height-image {
    margin-top: calc(0px - var(--app-body-container--padding-top));
  }
}