.block-available-locations {
  display: flex;
  .block-container {
    display: flex;
    flex-direction: column;  
    width: $screen-xl-max;
    margin: 0 auto;
    @include screen-xl-max {
      width: $mobile-container-width;
    }
    .heading {
      text-align:center;
      text-transform:uppercase;
      margin-bottom: 50px;
      @include screen-md-max {
        margin-bottom: 30px;
      }
    }
    .list-states {
      display: flex;
      justify-content: space-evenly;
      width: 80%;
      margin: 0 auto 50px;
      text-align: center;
      @include screen-lg-max {
        width: 100%;
        flex-wrap: wrap;
        margin: 0 auto 30px;
      }
      .state {
        align-self: flex-start;
        margin: 0;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 700;      
        color: var(--block-available-locations--state-colour);
        border-bottom: 5px solid transparent;
        transition: color $transition-03s, borderBottom $transition-03s;
        @include screen-md-max {
          flex-basis: 33.333%;
          margin-bottom: 5px;        
        }
      }
      .state.active {
        color: var(--block-available-locations--state-active-colour);
        text-decoration: underline;
        text-decoration-color: var(--block-available-locations--state-active-text-decoration-colour);
        text-decoration-thickness: 5px;
        text-underline-offset: 8px;
      }
    }
    .list-venues {    
      @include screen-md-max {
        flex-direction:column;
      }
      .region{
        display:none;
        animation: fade-out $transition-03s;
        &.show {
          display: flex;
          justify-content:space-between;
          flex-wrap:wrap;
          animation: fade-in $transition-03s;     
        }
      }
      .venue {      
        flex-basis:48%;
        align-items: center;     
        padding: 20px 0;      
        border-top: 1px solid var(--block-available-locations--border);
        &:last-child, &:nth-last-child(2){
          border-bottom: 1px solid var(--block-available-locations--border);
        }
        @include screen-md-max {
          flex-basis:100%;
          &:nth-last-child(2){
            border-bottom: none;
          }
        }
        .venue-details {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding:0 15px;
          .section-1 {
            display: flex;
            align-items: center;
            .venue-suburb {
              text-transform: var(--block-available-locations--venue-suburb-text-transform);
              margin: 0;
              margin-right: 20px;
              color: var(--block-available-locations--venue-suburb);
              font-weight: 600;
            }
          }
          .section-2 {
            display: flex;          
            .venue-address {
              align-self: center;
              @include screen-xl-max {
                max-width: 170px;
              }
              @include screen-md-max {
                margin-top: 10px;
              }
            }
            .book-button-desktop {
              display: block;
              @include screen-md-max {
                display: none;
              }
            }
            .book-button-mobile {
              display: none;
              @include screen-md-max {
                display: block;
              }
            }
          }
        }
      }
    }
    .block-book-now-button {
      text-decoration: none;
      span{
        text-align: center;
      }    
    }
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}