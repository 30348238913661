.venue-tiles-module {
  display: flex;
  flex-direction: column;
  width: $screen-xl-max;
  margin: 0 auto;
  @include screen-xl-max {
    width: $mobile-container-width;
  }
  .heading-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px 0;
  }
  .list-venues {
    .venue {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid var(--block-venue-tiles--venue-border-top);
      padding: 20px 0;
      .venue-details {
        display: flex;
        @include screen-md-max {
          flex-direction: column;
          max-width: 230px;
        }
        .venue-suburb {
          text-transform: uppercase;
          margin: 0;
          margin-right: 20px;
          color: var(--block-venue-tiles--venue-suburb-colour);
          font-weight: 600;
          @include screen-xl-max {
            max-width: 160px;
          }
        }
        .venue-address {
          align-self: center;
          @include screen-xl-max {
            max-width: 250px;
          }
          @include screen-md-max {
            margin-top: 10px;
          }
        }
      }
    }
    .venue:last-child {
      border-bottom: 1px solid var(--block-venue-tiles--venue-last-child-border);
    }
  }
}