.block-intro-quote {
  position: relative;
  display: flex;
  .block-container {
    display: flex;
    @include screen-md-max {
      flex-direction: column;
    }
    .body-container {
      flex: 2;
      margin-right: 40px;
      h1,
      h2 {
        margin-bottom: 50px;
        @include screen-md-max {
          margin-bottom: 30px;
        }
      }
      .btn {
        display:inline-block;
        text-decoration:none;
        margin-top: 50px;
        @include screen-md-max {
          margin-top: 30px;
        }
      }
    }
    .quote-container {
      flex: 1;
      @include screen-md-max {
        margin-left: 0px;
        margin-top: 30px;
      }
      .quote {
        font-weight: 700;
        line-height: 30px;
      }
    }
  }
}