.content-left{
    justify-content: left;
}

.content-center {
    justify-content: center;
}

.content-right {
    justify-content: right;
}