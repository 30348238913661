.block-content {
    .block-container {
        > *,
        div > * {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        li {
            font-size: 18px;
            line-height: 24px;
        }
        @include screen-xl-max {
            width: $mobile-container-width;
        }
        h1,
        h2 {
            font-weight: unset;
            margin-bottom: 50px;
            @include screen-md-max {
                margin-bottom: 30px;
            }
        }
        ol,
        ul {
            li {
                ::marker {
                    padding-right: 1rem;
                }
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}