.block-image {
  display: flex;
  position: relative;
  flex-direction: column;
  .block-container {
    display: flex;
    flex-direction: column;
    .title-container {
      display: var(--block-block-image--title-display);
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      text-align: center;
      @include screen-md-max {
        margin-bottom: 15px;
      }
      .title {
        font-weight: 700;
      }
    }
    .image-container {
      position: relative;
      display: block;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      @include screen-md-max {
        height: var(--block-block-image--image-container-height);
      }
      .text-container {
        display: var(--block-block-image--text-container-display);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        max-width: 80%;
        .title {
          color: #ffffff;
          text-align: center;
        }
      }
      .background-image {     
        top: var(--block-block-image--image-top);
        left: var(--block-block-image--image-left);
        width: 100%;
        object-fit: cover;
        @include screen-md-max{
          position: var(--block-block-image--image-position-mobile);
        }
      }
    }
  }
}