$promo-modal-padding: 30px;

.promo-modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999997;
  .promo-modal-details {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 628px;
    z-index: 5;
    @include screen-md-max {
      width: $mobile-container-width;
    }
    .image-container {
      position: relative;
      width: 100%;
      aspect-ratio: 3 / 2;
      overflow:hidden;
      background: var(--promo-modal--image-background);
      .icon-modal-close {
        position: absolute;
        top: $promo-modal-padding;
        right: $promo-modal-padding;
        width: 35px;
        height: 35px;
        z-index: 1;
        cursor: pointer;
        @include screen-md-max {
          top: 15px;
          right: 15px;
        }
      }
      .background-image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .details-container {
      display: flex;
      flex-direction: column;
      background: var(--promo-modal--details-background);
      color: var(--promo-modal--details-colour);
      padding: $promo-modal-padding;
      text-align:center;
      .heading {
        font-weight: 600;
        font-family: $fontSecondary;
        font-size: 28px;
        font-weight: bold;
        line-height: 36px;

        @include screen-md-max{
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 12px;
        }
      }
      .subheading {
        font-weight: 400;
        font-size: 18px;
        font-weight: normal;
        line-height: 32px;
        
        @include screen-md-max {
          font-size: 16px;
          line-height: 22px;
        }
      }
      .buttons {
        display: flex;
        justify-content:center;
        align-items: center;
        margin-top: 30px;
        .btn-sm {
          @include screen-md-max{
            font-size: 20px;
            line-height: 32px;
            height: 35px;
            padding: 0px 25px;
          }
        }
      }
      button {
        align-self: center;
      }
    }
  }
}