$padding: 30px;

.block-deals {
  display: block;
  overflow: hidden;
  .block-container {
    display: flex;
    flex-direction: column;
  }
  .title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    @include screen-md-max {
      margin-bottom: 30px;
    }
    .title {
      font-weight: 700;

    }
  }
  .heading {
    max-width: 325px;
    text-transform: var(--block-deals--heading-text-transform);
    font-family: var(--block-deals--heading-font-family);
    font-size: var(--block-deals--heading-font-size);
    line-height: var(--block-deals--heading-line-height);
  }
  .subheading {
    margin-bottom: 20px;
    font-weight: var(--block-deals--subheading-font-weight);
    font-size: var(--block-deals--subheading-font-size);
    line-height: var(--block-deals--subheading-line-height);
  }
  .btn-cta{
    background-color:var(--block-deals-cta-background-color);
    text-decoration: none;
    text-align: center;
    color: var(--block-deals-cta-text-color);
  }
  .blocks-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    position: relative;
    gap: 40px;
    width: 100%;
    @include screen-xl-max {
      gap: 30px;
    }
    .item {
      position: relative;
      display: inline-block;
      background: var(--block-deals--block-background);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: calc(33.333% - 27px);
      aspect-ratio: 1 / 1;
      overflow: hidden;
      @include screen-xl-max {
        width: calc(50% - 20px);
      }
      @include screen-md-max {
        width: 100%;
      }
      .gradient {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--image-gradient);
        z-index: 1;
      }
      .spare-background{
        background-color: var(--block-deals--spare-background);
        position: absolute;
        bottom: 0;
        height: 20px;
        width: 100%;
        z-index:1;        
        transition: height $transition-03s, background-color $transition-03s;
      }
      .hover-details {
        position: absolute;
        padding: $padding;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        .hover-details-container {
          position: relative;
          width: 100%;
          height: 100%;
          .details-container {
            position: absolute;
            width: 100%;
            top: var(--block-deals--details-container-top);
            left: 0;
            color: var(--block-deals--details-colour);
            z-index: 2;
            transition: top $transition-03s;
            .divider {
              position: relative;
              width: 0%;
              height: 1px;
              background: var(--block-deals--divider-background);
              transition: width $transition-03s;
              margin-bottom: 20px;
            }
            .description {
              opacity: 0;
              transition: opacity $transition-03s;
            }
          }
          .icon-info {
            position: absolute;
            opacity: 0;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            transition: opacity $transition-03s;
            cursor: pointer;
            z-index: 5;
          }         
          .btn-cta {
            position: absolute;
            opacity: 0;
            bottom: 0;
            right: 0;
            transition: opacity $transition-03s;}

            @include screen-md-max {
              opacity:1;
            }
        }
      }
    }
    .item:hover {
      .spare-background {                  
        height: 100%;
        background: var(--overlay-background);
      }
      .hover-details .hover-details-container .details-container {
        top: 0%;
      }
      .hover-details .hover-details-container .details-container .divider {
        width: 100%;
      }
      .hover-details .hover-details-container .btn-cta,
      .hover-details .hover-details-container .icon-info,
      .hover-details .hover-details-container .details-container .description {
        opacity: 1;
      }
    }
  }
  .blocks-container.carousel {
    @include screen-md-max {
      display: none;
    }
  }
  // .blocks-container::after {
  //   content: "";
  //   flex: 0 0 32%;
  //   max-width: 480px;
  //   @include screen-xl-max {
  //     flex: 0 0 48%;
  //   }
  // }
  .carousel-container {
    display: none;
    position: relative;
    margin: 0;
    width: 120%;
    left: 50%;
    transform: translateX(-50%);
    @include screen-lg-max {
      width: 95%;
      transform: unset;
      left: unset;
    }
    @include screen-md-max {
      width: 100%;
      display: block;
    }
    .deals-list-swiper {
      position: relative;
      width: 100%;
      overflow: visible !important;
      .swiper-wrapper {
        overflow: visible !important;
        .swiper-slide {
          display: flex;
          flex-direction: column;
          width: 50%;
          height: unset !important;
          position:relative;
          overflow: hidden;
          .icon-info {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 32px;
            height: 32px;
            transition: opacity $transition-03s;
            cursor: pointer;
            z-index: 5;
          }
          .spare-background{
            height:22px;
            background-color:var(--block-deals--spare-background);
            position:absolute;
            bottom:0px;
            width: 100%;
          }
          .image-container {
            position: relative;
            width: 100%;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            overflow: hidden;
            z-index: 0;
            .gradient {
              position: absolute;
              width: 100%;
              height: 100%;
              background: var(--image-gradient);
              z-index: 1;
            }
            .background-image {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 50%;
              z-index: 0;
            }
          }
          .details-container {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: var(--block-deals-carousel-details-container-padding);
            z-index: 999;
            align-items:start;
            p {
              margin: 0;
              padding: 0;
            }
            .heading,
            .subheading {
              margin: 0;
              padding: 0;
              color: var(--block-deals--heading-colour);
            }
            .heading {
              margin-bottom: 15px;             
            }
            .subheading {
              margin-bottom: 20px;
            }
            
            .btn-cta {
              margin-top: 20px;
            }
          }
        }
      }
      .swiper-pagination-bullet {
        pointer-events: all !important;
        width: 22px;
        height: 22px;
      }
      .swiper-pagination-bullet-active {
        background: var(--block-deals--swiper-pagination-bullet-active);
      }
      .swiper-button-prev,
      .swiper-button-next {
        z-index: 999999;
        @include screen-lg-max {
          display: none;
        }
      }
      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: '';
        display: none;
      }
      .swiper-button-prev {
        background-image: initial;
        left: 250px;
        @include screen-xl-max {
          left: 150px;
        }
      }
      .swiper-button-next {
        background-image: initial;
        right: 250px;
        @include screen-xl-max {
          right: 150px;
        }
      }
    }
  }
  .deal-modal {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999999;
    .deal-modal-details {
      position: relative;
      display: flex;
      flex-direction: column;
      background: var(--block-deals--modal-details-background);
      color: var(--block-deals--modal-details-colour);
      max-width: 600px;
      width: 600px;
      z-index: 5;
      @include screen-md-max {
        width: $mobile-container-width;
      }
      .details-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px;
        align-items:start;
        > * {
          margin-bottom: 15px;
          &:last-child {
            margin: 0;
          }
        }
        .btn-modal-cta{
          background-color: var(--block-deals--modal-button-background);
          color:  var(--block-deals--modal-button-text-color);
        }
        .btn-sm {
          text-decoration: none;
        }
      }
    }
    .icon-modal-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
      z-index: 999999;
      cursor: pointer;
    }
  }
  .deal-modal.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.block-deals.carousel {
  @include screen-md-max {
    width: 100%;
    .block-container {
      .blocks-container {
        display: none;
      }
    }
  }
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px !important;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  position: relative !important;
  bottom: 0 !important;
  padding: 50px 0 0 !important;
  @include screen-lg-max {
    padding: 30px 0 0 !important;
  }
}