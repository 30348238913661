.template-blogs-landing{
    position: relative;
    width: 100%;
    
    .blog-heading-container {
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
        .blog-heading {
            text-align: center;
            font-weight: 700;
            text-transform: none;
        }
    }

    @media (max-width: 1200px) {
        .blog-heading-container {
            width: 90%;
        }
    }
    
    .block.blogs-container {
        width: 1200px;
        margin-left: auto;
        margin-right: auto;
        .blog-filtered-by{
            margin-bottom: 10px;
    
            .remove-filter{
                background:none;
                color:var(--section-blog--remove-filter-color);
                border:none;
                text-decoration: underline;     
                padding-left:0px;       
            }
        }   
    
        .blog-content{
            padding: 20px 28px 30px;
            width: 100%;
            @include screen-md-max {
                padding: 12px 12px 30px;
            }
            .blog-properties{
                .date{
                    font-size:12px;
                    line-height: 20px;
                }
                .tag{
                    font-size:12px;
                    line-height: 20px;
                }
            }
    
            .blog-title {
                font-family: var(--section-blog--title-font-family);
                font-size: var(--section-blog--title-font-size);
                line-height: var(--section-blog--title-line-height);
                margin-bottom: 12px;
                margin-top: 20px;
                font-weight: var(--section-blog-title-font-weight-desktop);
                @include screen-md-max {
                    font-weight: var(--section-blog-title-font-weight-mobile);
                }
            }
            a {
                color:inherit;
                text-decoration: none;
            }
            .tag {
                color: var(--section-blog--tag-color);
                text-decoration: none;
            }
        }
    
        
    
        .blog-featured{
            display: flex;
            box-shadow: 2px 2px 5px rgba(0,0,0,.2);
            margin-bottom: 50px;
            @include screen-lg-max {
                flex-direction: column;
                margin-bottom: 30px;
                box-shadow: none;
            }
            .blog-content {
                flex: 1;
                background: #ffffff;
                @include screen-lg-max {
                    flex: unset;
                }
            }
            .blog-image-container {
                flex: 2;
                @include screen-lg-max {
                    flex: unset;
                }
                .blog-image{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .text {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 8;
                -webkit-box-orient: vertical;
            }
        }
    
        .blog-items{
            display:flex;
            flex-wrap: wrap;
            gap: 30px;        
            .blog-item{            
                width: calc(33.333% - 20px);
                box-shadow: 2px 2px 5px rgba(0,0,0,.2);
                animation: fade-in 1s;
                background: #ffffff;
                @include screen-xl-max {
                    flex-basis: calc(50% - 15px);
                }
                @include screen-md-max {
                    flex-basis: 100%;
                }
                .blog-image{
                    width:100%;
                    height: 226px;
                    object-fit: cover;
                }
            }
        }
    
        .blog-pagination{
            display:flex;
            justify-content: center;
            width: 100%;
            margin-top: 50px;
            @include screen-md-max {
                margin-top: 30px;
            }
            ul{
                list-style-type: none;
                display:flex;
                padding-left:0px;
                width: 210px;
                overflow-x:scroll;           
                scroll-behavior: smooth;
                -ms-overflow-style: none;  /* Internet Explorer 10+ */
                scrollbar-width: none;  /* Firefox */
                margin-bottom: 0;
                &.less-than-6{
                    justify-content: center;
                }
    
                &::-webkit-scrollbar { 
                    display: none;  /* Safari and Chrome */
                }
    
                li{
                    min-width:42px;
                    height:42px;
                    display:flex;
                    justify-content: center;
                    align-items: center;                
                    font-weight: 700;
                    font-size: 20px;
                    cursor: pointer;                                
    
                    &.active{
                        background-color: var(--section-blog-landing--pagination-active-background-color);
                        color: var(--section-blog-landing--pagination-active-text-color);
                        border-radius: 50%;
                        cursor: default;
                    }
                }
            }
    
            .next{           
                height:42px;
                display:block;
                           
                background: var(--section-blog-landing--pagination-next-image);
                background-repeat:no-repeat;            
                cursor:pointer;
                margin-left:23px;
                width:22px;
                border:none;
    
                &.disabled{
                    cursor: initial;
                    opacity: 0.2;
                }
            }
    
            .previous{           
                height:42px;
                display:block;
                transform: rotate(180deg);
                background: var(--section-blog-landing--pagination-next-image);
                background-repeat:no-repeat;            
                cursor:pointer;
                margin-right:23px;
                width:22px;
                border:none;
    
                &.disabled{
                    cursor: initial;
                    opacity: 0.2;
                }
            }
        }
    
        .blog-tags-filter{
            margin-top: 50px;
            display:flex;
            @include screen-lg-max {
                flex-direction: column;    
                align-items: center;        
            }
            @include screen-md-max {
                margin-top: 30px;
            }
            .label{
                width: 134px;
                font-size: 18px;
                line-height: 32px;
                @include screen-md-max {
                    width: unset;
                }
            }
    
            ul{
                list-style-type: none;
                display:flex;
                flex-wrap: wrap;
                flex:1;
                gap: 2.5%;
                padding-left: 0px;
                margin-bottom: 0;
                @include screen-lg-max {
                    justify-content: space-around;
                }
                li{
                    font-size: 18px;
                    line-height: 32px;
                    font-weight: 700;
                    color: var(--section-blog--tag-color);
                    text-decoration: underline;
                    cursor:pointer;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .block.blogs-container {
            width: 90%;
        }
    }
}
h1.blog-heading {
    text-transform: uppercase;
    font-weight: 700;
    padding-right: 30px;
}