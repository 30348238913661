html,
body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.app {
    &.disable-scrolling {
        overflow-y: hidden;
    }
}

.app-body-container {
    padding-top: var(--app-body-container--padding-top);
    transition: all 0.3s;
    @include screen-xl-max {
        padding-top: var(--app-body-container--padding-top-screen-xl-max);
    }
    @include screen-lg-max {
        padding-top: var(--app-body-container--padding-top-screen-lg-max);
    }
    @include screen-md-max {
        padding-top: var(--app-body-container--padding-top-screen-md-max);
    }
}