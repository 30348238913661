.btn {
  font-size: 28px;
  line-height: 28px;
  padding: 16px 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  font-weight: 700;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 10px 20px;
  border: none;
  transition: $transition-03s;
  cursor: pointer;

  // contact form overrides
  margin: 0;
  width: unset;
  height: unset;
  vertical-align: unset;
  text-overflow: unset;
}

.btn:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
}

.btn-sm {
  background: var(--button-small-background);
  color: var(--button-small-colour);
  font-size: 20px;
  line-height: 20px;
  padding: var(--button-small-padding);
  box-shadow: var(--button-small-box-shadow);

  &:hover{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
    background: var(--button-small-hover-background);
    color: var(--button-small-colour);
  }

}


.btn-text {
  font-size: 20px;
  line-height: 20px;
  padding: 0;
  font-weight: 600;
  background: none;
  color: var(--button-text-colour);
  text-decoration: none;
}

.btn-primary {
  background: var(--button-primary-background);
  color: var(--button-primary-colour);
}

.btn-primary:hover {
  background: var(--button-primary-hover-background);
}

.btn-secondary{
  background: var(--button-secondary-background);
  color: var(--button-secondary-colour);
}

.btn-secondary:hover{
  background: var(--button-secondary-hover-background);
}

.btn-white {
  background: var(--button-white-background);
  color: var(--button-white-colour);
}

.btn-white:hover {
  background: var(--button-white-hover-background);
  color: var(--typography-link-hover-colour);
}

.btn-black {
  background: var(--button-black-background);
  color: var(--button-black-colour);

  &:hover{
    background: var(--button-black-hover-background);
  }

.btn-black:hover {
  background: var(--button-black-hover-background);
}

.btn-sm-black {
    background: var(--button-black-background);
    color: var(--button-small-colour);
    font-size: 20px;
    line-height: 20px;
    padding: 11px 25px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .btn-sm-black:hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
    color:  var((--neutral-1));
  }
  
  .btn-sm-black:hover {
    background: var(--button-black-background);
  }
  &[disabled]{
    opacity:0.25;
    cursor:default;
  }
}
