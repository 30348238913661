.block-form {
  position: relative;
  min-height: 200px;
  .loading-container {
    z-index: 1000;
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: visible;
    height: auto;
    width: auto;
    pointer-events: all;
    display: none;
    &.show {
      display: flex;
    }
    .container {
      display: flex;
      justify-content: center;
      img {
        max-height: 300px;
        max-width: 70%;
      }  
    }
    
  }
  form,
  .thank-you {
    display: none;
    &.active {
      display: block;
    }
  }
  .fieldtype {
    margin-bottom: 20px;
  }
  .form-error {
    .msg {
      border: 1px solid var(--block-form-field-error-colour);
      color: var(--block-form-field-error-colour);
      padding: 20px;
      font-weight: bold;
    }
  }
  
  // Standard Form Styles
  form {
    label {
      font-weight: bold;
      margin-bottom: 10px;
    }

    input[type=text],
    input[type=password],
    input[type=tel],
    input[type=number],
    input[type=email],
    input[type=url],
    input[type=file],
    select {
      padding: 10px;
      border-radius: 10px;
      border: var(--block-form-field-border);
      width: 100%;
    }
    
    select {
      color: var(--block-form-select-color)
    }

    textarea {
      padding: 10px;
      border-radius: 10px;
      border: var(--block-form-field-border);
      width: 100%;
      height: 156px;
    }

    input[type=radio],
    input[type=checkbox] {
      display: grid;
      place-content: center;
      appearance: none;
      min-height: 25px;
      max-height: 25px;
      min-width: 25px;
      max-width: 25px;
      background: var(--block-form-field-checkbox-background);
      border-radius: 5px;
      border: var(--block-form-field-checkbox-border);

      &::before {
        content: "";
        width: 0.90em;
        height: 0.90em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em var(--block-form-field-checkbox-colour);
        clip-path: polygon(19% 53%, 8% 65%, 42% 94%, 100% 24%, 88% 15%, 42% 73%);
      }

      &:checked {
        background: var(--block-form-field-checkbox-checked-background);
        border: var(--block-form-field-checkbox-checked-bborder);

        &::before {
          transform: scale(1);
          box-shadow: inset 1em 1em var(--block-form-field-checkbox-checked-colour);
        }
      }
    }
  }
  
  // Field Types
  .fieldtype {
    margin-bottom: 30px;
  }
  
  .fieldtype-dropdown {
    .wrapper {
      position: relative;

      svg {
        position: absolute;
        top: 15px;
        right: 10px;
        width: 25px;
        pointer-events: none;
      }
    }
  }

  .fieldtype-date,
  .fieldtype-date-with-date-check {
    .wrapper {
      position: relative;

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 25px;
        pointer-events: none;
      }
    }
  }

  .fieldtype-date-and-time {
    .wrapper {
      position: relative;

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 25px;
        pointer-events: none;
      }
    }
  }
  .fieldtype-date,
  .fieldtype-date-and-time,
  .fieldtype-date-with-date-check {
    input[type="date"],
    input[type="datetime-local"]{
      position: static;
      left: auto;
      top: auto;
      text-align: left;
      background: var(--dropdown-select--input-date-background);
      width: 100%;
      min-width: 95%;
      padding: 10px;
      border-radius: 10px;
      border: var(--block-form-field-border);
      background: transparent;
      height: 100%;
      min-height: 46px;
    }
  }


  .fieldtype-checkbox {
    label {
      display: flex;
      align-items: center;
      
      input {
        margin-right: 10px;
      }
    }
  }

  .fieldtype-multiple-choice {
    label {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }

  .fieldtype-single-choice {
    label {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }

  .fieldtype-data-consent {
    label {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }
    }
  }

  .error-message {
    display: none;
  }

  .error {
    
    input[type=text],
    input[type=password],
    input[type=tel],
    input[type=number],
    input[type=email],
    input[type=url],
    input[type=file],
    select {
      border-color: var(--block-form-field-error-colour);
    }

    input[type=radio],
    input[type=checkbox] {
      border-color: var(--block-form-field-error-colour);
    }


    .error-message {
      display: flex;
      align-items: center;
      color: var(--block-form-field-error-colour);
      margin-top: 10px;
      
      svg {
        height: 25px;
        width: 25px;
        color: var(--block-form-field-error-colour);
        margin-right: 10px;
      }
      
      .msg {
        font-weight: bold;
      }
      
    }
  }
  
  .container {
    max-width: 100%; // unfortunately need this to constrain the width.
  }

  @include media-breakpoint-up(xl) {
    .container {
      max-width: 100%; // unfortunately need this to constrain the width.
    }
  }
}