$padding: 35px;

.block-menu {
  position: relative;
  .block-container {
    .top {
      width: 768px;
      margin: 0 auto;
      padding: 0 $padding $padding;
      @include screen-xl-max {
        width: 100%;
        margin: 0;
        padding: 0 0 $padding;
      }
      .tabs-container {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 25px;
        @include screen-xl-max {
          flex-direction: column;
          margin-bottom: 0;
        }
        .tab {
          align-self: flex-start;
          margin: 0;
          padding-bottom: 5px;
          cursor: pointer;
          color: var(--block-food-drinks--tab-colour);
          border-bottom: 5px solid transparent;
          transition: color $transition-03s, borderBottom $transition-03s;
          font-weight: 700;
          text-transform: uppercase;
          @include screen-xl-max {
            margin-bottom: 15px;
          }
        }
        .tab.active {
          color: var(--block-food-drinks--tab-active-colour);
          border-bottom: 5px solid var(--block-food-drinks--tab-active-border-bottom);
        }
      }
      .description-container {
        display: none;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        @include screen-xl-max {
          justify-content: flex-start;
          text-align: left;
        }
        p {
          font-weight: 400;
        }
      }
      .description-container.show {
        display: flex;
      }
    }
    .bottom {
      display: flex;
      flex-direction: row;
      width: 100%;
      @include screen-xl-max {
        flex-direction: column;
      }
      .columns-container {
        display: flex;
        flex: 1;
        @include screen-xl-max {
          margin: 0;
        }
        @include screen-md-max {
          flex-direction: column;
          border-bottom: none;
        }
        .column {
          flex: 1;
          margin-right: 20px;
          border-top: 1px solid var(--block-food-drinks--column-border-top);
          @include screen-md-max {
            margin: 0;
          }
        }
        .column:last-child {
          margin: 0;
          @include screen-md-max {
            border-top: none;
          }
        }
      }
    }
    .external-pdf {
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      a {
        color: var(--hyperlink);
        font-weight: 400;
      }
      @include screen-xl-max {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    .external-pdf.show {
      display: flex;
    }
    .external-pdf-bottom {
      padding: 40px 0 0;
      a {
        color: var(--hyperlink);
        font-weight: 400;
      }
    }
  }
}

.block-menu-section {
  display: none;
  border-bottom: 1px solid var(--block-food-drinks--menu-section-border-bottom);
  padding: 30px $padding;
  animation: fade-out $transition-03s;
  @include screen-md-max {
    padding: 12px 0;
    cursor: pointer;
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: var(--block-food-drinks--menu-item-title-font-size);
      line-height: var(--block-food-drinks--menu-item-title-line-height);
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      @include screen-md-max {
        font-size: var(--block-food-drinks--menu-item-title-mobile-font-size);
        line-height: var(--block-food-drinks--menu-item-title-mobile-line-height);
      }
    }
    .title-arrows-container {
      display: none;
      position: relative;
      width: 22px;
      height: 22px;
      @include screen-md-max {
        display: flex;
      }
      .arrow-left {
        position: absolute;
        width: 100%;
        height: 100%;
        will-change: transform;
        transition: transform $transition-03s;
      }
    }
  }
  .category-description {
    margin-bottom: 35px;
    p {
      font-size: var(--block-food-drinks--menu-item-subtext-font-size);
      line-height: var(--block-food-drinks--menu-item-subtext-line-height);
    }
    @include screen-md-max {
      margin-bottom: 22px;
    }
  }
  
  .item-rows {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    @include screen-md-max {
      display: none;
    }
    .item-row {
      display: flex;
      justify-content: space-between;
      align-items: top;
      margin-bottom: 12px;
      .item-description {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        .item-title-container {
          img {
            margin-left: 5px;
          }
        }
        .name {
          display: inline;
          font-weight: 700;
          font-size: var(--block-food-drinks--menu-item-name-font-size);
          line-height: var(--block-food-drinks--menu-item-name-line-height);
          margin: 0;
          padding: 0;
        }
        .extra {
          max-width: 400px;
          font-size: var(--block-food-drinks--menu-item-subtext-font-size);
          line-height: var(--block-food-drinks--menu-item-subtext-line-height);
        }
      }
      .item-price {
        text-align: right;
        min-width: 100px;
        .price {
          font-weight: 700;
          font-size: var(--block-food-drinks--menu-item-price-font-size);
          line-height: var(--block-food-drinks--menu-item-price-line-height);
          text-align: right;
        }
      }
    }
    .item-row:last-child {
      margin-bottom: 0;
    }
  }
}

.block-menu-section.show {
  display: block;
  animation: fade-in $transition-03s;
}

.block-menu-section.show-accordion {
  @include screen-md-max {
    .title-container {
      .title-arrows-container {
        .arrow-left {
          transform: rotate(-90deg);
        }
      }
    }
    .item-rows {
      display: flex;
    }
  }
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}