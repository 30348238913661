$padding: 30px;
$duration: 0.3s;

.block-cta-grid {
  .block-container {
    display: flex;
    flex-direction: column;
    .title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      @include screen-md-max {
        margin-bottom: 30px;
      }
      .title {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
    .items-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      position: relative;
      gap: 40px;
      @include screen-xl-max {
        width: 100%;
        gap: 30px;
      }
      .item {
        position: relative;
        display: inline-block;
        background: var(--block-cta-grid--block-background);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        aspect-ratio: 16 / 9;
        flex-basis: calc(33.333% - 27px);
        cursor: pointer;
        overflow: hidden;
        transition: $transition-03s;
        @include screen-xl-max {
          flex-basis: calc(50% - 20px);
        }
        @include screen-md-max {
          flex-basis: 100%;
        }
        .background-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s;
          z-index: 1;
        }
        .heading {
          position: absolute;
          bottom: 20px;
          left: 20px;
          color: var(--block-cta-grid--block-heading-colour);
          margin: 0;
          font-weight: var(--block-cta-grid--heading-font-weight);
          text-transform: var(--block-cta-grid--heading-text-transform);
          z-index: 3;        
        }
        .gradient {
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--image-gradient);
          z-index: 2;
        }
      }
    }
    .blocks-container::after {
      content: "";
      flex: 0 0 32%;
      max-width: 480px;
      @include screen-xl-max {
        flex: 0 0 48%;
      }
    }
  }
}

.item:hover {
  .background-image {
    transform: scale(1.3);
  }
}