$padding: 20px;

.block-filtered-package {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  .title-container {
    display: flex;
    justify-content: center;
    padding: $padding;
    background: var(--block-filtered-package--title-background);
    color: var(--block-filtered-package--title-colour);
    .container {
      display: flex;
      width: $screen-md-max;
      margin: 0 auto;
      @include screen-md-max {
        width: 100%;
        flex-direction: column;
      }
      .text-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 250px;
        margin-right: 10px;
        @include screen-md-max {
          justify-content: flex-start;
          width: unset;
          margin-bottom: 5px;
        }
        .text {
          font-weight: var(--block-filtered-package--text-font-weight);
        }
      }
    }
  }
  .filter-container {
    background: var(--block-filtered-package--filter-background);
    padding: 32px 0;
    .filter {
      display: flex;
      width: 768px;
      justify-content: space-between;
      margin: 0 auto;
      .title {
        color: var(--block-filtered-package--filter-title-colour);
        font-weight: 600;
      }
    }
  }
  .modal-trigger {
    display: flex;
    align-items: center;
    position: relative;
    color: var(--hyperlink); // TODO 
    text-decoration: underline;
    margin-top: 2px;
    margin-left: 32px;
    cursor: pointer;
    @include screen-md-max {
      align-items: flex-start;
    }
    .modal-trigger-label {
      font-size: 18px;
      line-height: 32px;
      @include screen-md-max {
        font-size: 16px;
        line-height: 25px;
      }
    }
    &.has-icon{
      margin-left:0px;
      .modal-trigger-label {
        margin-left: 11px;
      }
    }
  }
  .carousel-container {
    position: relative;
    margin: 0;
    margin-top: 20px;
    flex-grow: 1;
    margin: 0;
    @include screen-lg-max {
      width: 100%;
      margin: 20px auto 0;
      transform: unset;
      left: unset;
    }
    .inner-carousel {
        position: relative;
        @include screen-xl-max {
            width: 100%;
            overflow: visible;
        }
    }
    .placeholder-please-select-message {
      position: relative;
      top: 0;
      margin-top: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      aspect-ratio: 16 / 9;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--block-filtered-package--please-select-background);
      z-index: 1;
      text-align: center;
      box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
      @include screen-md-max {
        width: $mobile-container-width;
        margin-top: 30px;
      }
    }
    .filtered-package-swiper {
      position: relative;
      width: 100%;
      margin: 40px auto 0;
      @include screen-xl-max {
          width: 100%;
      }
      @include screen-md-max {
        margin: 0px auto 0;
      }
      .swiper-wrapper {
        overflow: visible !important;
        &.placeholders {
          .swiper-slide {
            .bottom-text {
              padding: 0 20px 20px 20px;
              text-align: center;
              font-weight: 600;
              .divider {
                width: 100%;
                height: 1px;
                background: var(--block-filtered-package--details-bottom-border-top);
                margin-bottom: 20px;
              }
            }
          }
        }
        &.stacked {
          display:flex;
          gap: 30px;
          width: 100%;
          flex-wrap: wrap;

            .swiper-slide{
                width: 370px;
                @include screen-md-max{
                    width: 100%;
                }
            }
        }
        .swiper-slide {
          background: var(--block-filtered-package--swiper-slide-background);
          display: flex !important;
          flex-direction: column;
          box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
          overflow: hidden;
          height: unset;
          .image-container {
            position: relative;
            width: 100%;
            aspect-ratio: 16 / 9;
            overflow: hidden;
            .title {
              position: absolute;
              bottom: $padding;
              left: $padding;
              font-weight: var(--block-filtered-package--image-title-font-weight);
              color: var(--block-filtered-package--image-title-colour);
              text-transform: uppercase;
              z-index: 2;
              &.hidden{
                visibility: hidden;
              }
            }
            .gradient {
              position: absolute;
              width: 100%;
              height: 100%;
              background: var(--image-gradient);
              z-index: 1;
            }
            .background-image {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 50%;
              z-index: 0;
            }
          }
          .details-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            flex-grow: 1;
            .top {
              padding-bottom: 40px;
              .detail-row {
                display: flex;
                align-items: flex-start;
                margin-bottom: calc($padding / 2);
                .description {
                  font-weight: 400;
                }
                .icon {
                  margin-right: 10px;
                }
                a {
                  display: flex;
                }
              }
            }
            .bottom {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              padding-top: $padding;
              border-top: 1px solid var(--block-filtered-package--details-bottom-border-top);
              .left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                align-items: flex-start;
                .price {
                  text-transform: lowercase;
                  font-weight: 800;
                }
              }
            }
          }
        }
      }
      .swiper-pagination {
        position: relative !important;
        padding: 50px 0 0;
        bottom: 0;
        @include screen-md-max {
          padding: 30px 0 0 !important;
        }
      }
      .swiper-pagination-lock {
        display: block !important;
      }
      .swiper-pagination-bullet {
        pointer-events: all !important;
        width: 22px;
        height: 22px;
      }
      .swiper-pagination-bullet-active {
        background: var(--block-filtered-package--swiper-pagination-bullet-active);
      }
    }
  }
  .swiper-button {
    opacity: 0;
    transition: opacity 0.3s;
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0 !important;
    transition: opacity 0.3s;
  }
  .swiper-button-prev,
  .swiper-button-next {
    z-index: 999999;
    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: '';
    display: none;
  }
  .swiper-button-prev {
    background-image: initial;
    left: -50px;
    top: 45%;
    transform: translateY(-50%);
    z-index: 99998;
  }
  .swiper-button-next {
    background-image: initial;
    right: -50px;
    top: 45%;
    transform: translateY(-50%);
    z-index: 99998;
  }
  .additional-information-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .package-modal {
      display: none;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: 999999;
      .package-modal-details {
        position: relative;
        display: flex;
        flex-direction: column;
        background: var(--block-filtered-package--package-modal-details-background);
        color: var(--block-filtered-package--package-modal-details-colour);
        max-width: 600px;
        width: 600px;
        z-index: 5;
        @include screen-md-max {
          width: $mobile-container-width;
        }
        .details-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 30px;
        }
      }
      .icon-modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 35px;
        height: 35px;
        z-index: 999999;
        cursor: pointer;
      }
  }
  .package-modal.active {
      display: flex;
  }
}