.component-footer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--footer-background);
    color: var(--footer-colour);
    .footer-top {
      display: flex;
      padding: 40px 30px;
      @include screen-lg-max {
        padding: 20px 30px;
      }
      .footer-top-container {
        display: flex;
        width: $screen-xl-max;
        margin: 0 auto;
        @include screen-lg-max {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        .footer-top-section {
          display: flex;
          flex-direction: column;
          flex: 1;
          @include screen-lg-max {
            padding-bottom: 30px;
          }
          .logo-container,
          .contact-container,
          .brands-container {
            display: flex;
          }
          .logo-container {
            margin-bottom: 30px;
            @include screen-lg-max {
              display: none;
            }
            .logo {
              width: 218px;
            }
          }
          .contact-container {
            display: flex;
            margin-bottom: 20px;
            @include screen-lg-max {
              flex-direction: column;
              align-items: center;
              margin: 0;
            }
            .contact-section {
              display: flex;
              flex-direction: column;
              margin-right: 40px;
              @include screen-lg-max {
                margin: 0;
                margin-bottom: 30px;
              }
              .body {
                .item .social {
                  width: 23px;
                  height: 23px;
                }
              }
              .heading {
                @include screen-lg-max {
                  text-align: center;
                }
              }
            }
            .contact-section:last-child {
              margin-right: 0;
            }
          }
          .brands-container {
            display: flex;
            flex-direction: column;
            @include screen-lg-max {
              align-items: center;
            }
            .body {
              @include screen-lg-max {
                flex-direction: var(--footer-logo-other-brands-flex-direction);
              }
              .logo {
                width: var(--footer-logo-other-brands-desktop-width);
                @include screen-md-max {
                  width: var(--footer-logo-other-brands-mobile-width);
                  height: var(--footer-logo-other-brands-mobile-height);
                }
              }
              .item {
                @include screen-lg-max {
                  margin: 0;
                  margin-bottom: var(--footer-logo-other-brands-margin-bottom-first);
                }
              }
              .item:last-child {
                @include screen-lg-max {
                  margin: 0;
                  margin-bottom: var(--footer-logo-other-brands-margin-bottom-last);
                }
              }
            }
          }
          .heading {
            color: var(--footer-top-section-heading-colour);
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: var(--footer-items-font-weight);
          }
          .body {
            display: flex;
            align-items: center;
            a {
              text-decoration: none;
              color: var(--footer-colour);
            }
            .item {
              margin-right: 20px;
            }
            .item.number {
              font-weight: 600;
            }
            .item.number:hover {
              text-decoration: underline;
            }
            .item:last-child {
              margin-right: 0;
            }
          }
          .navigation-menu {
            display: flex;
            justify-content: flex-end;
            @include screen-lg-max {
              justify-content: unset;
              flex-wrap: wrap;
            }
            @include screen-md-max {
              justify-content: center;
            }
            .navigation-menu-container {
              display: flex;
              width: 92%;
              @include screen-md-max {
                flex-wrap: wrap;
                width: 80%;
                gap: 20px;
                margin: 0 auto;
                justify-content: flex-start;
              }
              .column {
                display: flex;
                margin-right: 40px;
                @include screen-lg-max {
                  flex-basis: calc(50% - 10px);
                  max-width: calc(50% - 10px);
                  flex-direction: column;
                }
                .navigation-items {
                  margin: 0;
                  padding: 0;
                  margin-right: 40px;
                  align-self: flex-start;
                  a {
                    text-decoration: none;
                    font-weight: var(--footer-items-font-weight);
                    color: var(--footer-colour);
                  }
                  a:hover {
                    text-decoration: underline;
                  }
                  .item {
                    list-style-type: none;
                    text-transform: uppercase;
                    color: var(--footer-colour);
                    margin-bottom: 12px;
                  }
                }
                .navigation-items:last-child {
                  margin-right: 0;
                }
                @include screen-md-max {
                  margin-right: 0;
                }
              }
              .column:last-child {
                margin: 0;
              }
              .column:nth-child(2n) {
                @include screen-md-max {
                  margin-right: 0;
                }
              }
            }
          }
        }
        .footer-top-section.navigation {
          @include screen-lg-max {
            border-top: 1px solid var(--footer-desktop-border-top);
            padding: 30px 0;
          }
        }
      }
    }
    .footer-bottom {
      display: flex;
      width: 100%;
      background: var(--footer-bottom-background);
      padding: 4px 30px;
      @include screen-lg-max {
        padding: 20px 30px;
      }
      .footer-bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: $screen-xl-max;
        margin: 0 auto;
        @include screen-lg-max {
          flex-direction: column-reverse;
          width: 100%;
        }
        .footer-bottom-section {
          a,
          .bottom-item {
            margin-right: 20px;
            font-weight: var(--footer-items-font-weight);
            text-transform: uppercase;
            color: var(--footer-colour);
            @include screen-md-max {
                margin-right: 0;
            }
          }
          .bottom-item:hover {
            text-decoration: underline;
          }
          .bottom-item:last-child {
            margin-right: 0;
          }
          a {
            text-decoration: none;
          }
        }
        .footer-bottom-section.bottom-items {
          @include screen-lg-max {
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
            margin-top: 20px;
          }
          .bottom-item {
            @include screen-lg-max {
              margin: 0;
              margin-bottom: 10px;
            }
          }
          .bottom-item:first-child {
            @include screen-lg-max {
              margin-bottom: 0;
            }
          }
        }
        .footer-bottom-section.bottom-items > * {
          @include screen-md-max {
            margin-bottom: 20px;
          }
        }
      }
    }

    &.landing-page--footer{
      .brands-container{
        align-items: center;

        .heading.small-text{
          color:var(--footer-colour);
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }