// TODO is this used?

$padding: 20px;

.contact-item {
  width: 390px;
  background: var(--item-contact-item--background);
  color: var(---item-contact-item--color);
  .top {
    padding: $padding;
    .heading {
      font-weight: 600;
    }
  }
  .bottom {
    padding: $padding;
    .specialist {
      margin-bottom: 40px;
      .name-role,
      .phone-email {
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 600;
        }
        .phone,
        .email {
          text-decoration: none;
          color: var(--item-contact-item--color);
          font-weight: 600;
        }
      }
      .phone-email {
        margin-top: 10px;
        .details-phone,
        .details-email {
          .phone,
          .email {
            width: 18px;
            margin-right: 5px;
          }
        }
      }
    }
    .specialist:last-child {
      margin: 0;
    }
  }
}