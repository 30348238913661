$padding: 30px;

.block-featured-article {
  position: relative;
  display: flex;
  .block-container {
    display: flex;
    flex-direction: column;
    .title-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      @include screen-md-max {
        margin-bottom: 30px;
      }
      .title {
        text-transform: uppercase;
        font-weight: 700;
      }
    }
    .blocks-container {
      display: flex;
      flex-flow: row wrap;
      gap: 30px;
      position: relative;
      @include screen-xl-max {
        width: 100%;
      }
      .item {
        position: relative;
        display: inline-block;
        background: var(--block-featured-article--block-background);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        aspect-ratio: 1 / 1;
        width: calc(33% - 17px);
        cursor: pointer;
        overflow: hidden;
        @include screen-xl-max {
          width: calc(50% - 15px);
        }
        @include screen-md-max {
          width: 100%;
          aspect-ratio: 8 / 3;
        }
        .gradient {
          position: absolute;
          width: 100%;
          height: 100%;
          background: var(--image-gradient);
          z-index: 1;
        }
        .spare-background{
          background-color: var(--block-featured-article--spare-background);
          position: absolute;
          bottom: 0;
          height: 20px;
          width: 100%;
          z-index:1;        
          transition: height $transition-03s, background-color $transition-03s;
        }
        .hover-details {
          position: absolute;
          padding: $padding;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          .hover-details-container {
            position: relative;
            width: 100%;
            height: 100%;
            .details-container {
              position: absolute;
              width: 100%;
              top: 80%;
              left: 0;
              color: var(--block-featured-article--hover-details-container-colour);
              z-index: 2;
              transition: top $transition-03s;
              @include screen-md-max {
                top: 50%;
                transform: translateY(-50%);
              }
              .heading {
                max-width: 325px;
                font-weight: 700;
                text-transform: uppercase;
                font-size: map_get($h2, 'fontSize');
                line-height: map_get($h2, 'lineHeight');
                color: var(--block-featured-article--heading);
                @include screen-md-max {
                  margin: 0;
                  margin-bottom: 10px;
                }
              }
              .subheading {
                text-transform: uppercase;
                font-size: map_get($h4, 'fontSize');
                line-height: map_get($h4, 'lineHeight');
                @include screen-md-max {
                  margin: 0;
                  font-weight: 400;
                }
              }
              .divider {
                position: relative;
                width: 0%;
                height: 1px;
                background: var(--block-featured-article--divider-background);
                transition: width $transition-03s;
                margin-bottom: 20px;
                @include screen-md-max {
                  display: none;
                }
              }
              .description {
                opacity: 0;
                transition: opacity $transition-03s;
                @include screen-md-max {
                  display: none;
                }
              }
            }
            button {
              position: absolute;
              opacity: 0;
              bottom: 0;
              right: 0;
              transition: opacity $transition-03s;
              background-color:var(--block-featured-article--button-background);
              color:var(--block-featured-article--button-text);
              @include screen-md-max {
                display: none;
              }
            }
          }
        }
      }
      @include screen-lg-min {
        .item:hover {
          .spare-background {                  
            height:100%;
            background:var(--overlay-background);
          }
          .hover-details .hover-details-container .details-container {
            top: 0%;
          }
          .hover-details .hover-details-container .details-container .divider {
            width: 100%;
          }
          .hover-details .hover-details-container button,
          .hover-details .hover-details-container .icon-info,
          .hover-details .hover-details-container .details-container .description {
            opacity: 1;
          }
        }
      }
    }
    .blocks-container::after {
      content: "";
      flex: 0 0 32%;
      max-width: 480px;
      @include screen-xl-max {
        flex: 0 0 48%;
      }
    }
  }
}