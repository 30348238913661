.block-intro-cta {
  position: relative;
  width: 100%;
  .block-container {
    display: flex;
    width: $screen-xl-max;
    margin: 0 auto;
    @include screen-xl-max {
      width: $mobile-container-width;
    }
    @include screen-md-max {
      flex-direction: column;
    }
    .body-container {
      flex: 1.9;
    }
    .cta-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 80px;
      @include screen-md-max {
        margin-left: 0px;
        margin-top: 20px;
      }
      .text {
        font-weight: 700;
        text-transform: var(--block-intro-cta--section-right-text-transform);
        line-height: 30px;
        @include screen-md-max {
          font-size: var(--block-intro-cta--section-right-font-size-mobile);
        }
      }
      .btn-text {
        margin-top: 30px;
        @include screen-md-max {
          margin-top: 15px;
        }
      }
      button {
        align-self: flex-start;
      }
    }
  }
}