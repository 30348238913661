.block-text-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: var(--block-text-hero--heading-background);
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
  
  .heading {
    position: absolute;
    color: var(--block-text-hero--heading-colour);
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    z-index: 5;
    text-align: center;
    &.hide-heading {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
    @include screen-md-max {
      max-width: 80%;
    }
  }
  .background-image {
    width: 100vw;
    height: auto;
  }
  .overlay-opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--block-text-hero--opacity-overlay-background);
    z-index: 1;
  }
}