$content-sections-padding: 25px;

.block-content-section {
  position: relative;
  width: 100%;
  .block-container {
    .content-section {
      display: flex;
      width: 100%;
      padding-bottom: calc($content-sections-padding * 2);
      background: var(--block-content-sections--background);
      @include screen-xl-max {
        flex-direction: column;
      }
      .heading-container {
        flex: 1.55;
        margin-bottom: 50px;
        @include screen-xl-max {
          margin-bottom: 30px;
        }
        .heading {
          text-transform: uppercase;
          font-weight: 700;
          padding-right: 30px;
          @include screen-xl-max {
            padding-right: 0;
          }
          @include screen-md-max {
            text-align: center;
          }
        }
      }
      .content-container {
        display: flex;
        flex: 3;
        flex-direction: column;
        > * {
          margin-bottom: 15px;
          &:last-child {
            margin: 0;
          }
        }
        .btn {
          margin-top: 20px;
        }
      }
    }
    .content-section:last-child {
      padding-bottom: 0;
    }
  }
}