﻿.block {
  margin-top: 50px;
  margin-bottom: 50px;
  
  // Uncomment for dev testing of module spacings
  //background: lightgreen;
  //border: 1px dashed orchid;
  // .block-container {
  //   border: 3px dashed orchid;
  //     background: lightblue;
  // }
  
  @include screen-md-max {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.block-container {
  width: $screen-xl-max;
  margin: 0 auto;
  @include screen-xl-max {
    width: $mobile-container-width;
  }
}

.block-container.border-top {
  padding-top: 50px;
  border-top: 1px solid var(--block-cta-grid--border);
  @include screen-md-max {
    padding-top: 30px;
  }
}

.block-container.border-bottom {
  padding-bottom: 50px;
  border-bottom: 1px solid var(--block-cta-grid--border);
  @include screen-md-max {
    padding-bottom: 30px;
  }
}

