.error-404 {
    position: relative;
    display: flex;
    width: 100%;
    height: 953px;
    margin-bottom: 0 !important;
    .background-image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .text-container {
        position: absolute;
        top: 50%;
        right: 0;
        width: 50%;
        transform: translateY(-50%);
        color: var(--block-error-404--text-colour);
        @include screen-lg-max {
            width: 65%;
        }
        @include screen-md-max {
            position: relative;
            width: $mobile-container-width;
            margin: 0 auto;
            right: unset;
            transform: unset;
        }
        .title,
        .subheading {
            font-weight: 400;
        }
        .title {
            margin-bottom: 25px;
        }
        .subheading {
            width: 75%;
            margin-bottom: 50px;
            @include screen-lg-max {
                width: 90%;
            }
            @include screen-md-max {
                width: 100%;
            }
        }
    }
}