.teeg-loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 999999;  
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s 0.3s;
    display: flex;
    &.active {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s;
    }
    .container {
      position: relative;
      width: 50%;
      height: 200px;
      
      @include screen-md-max{
        width: 100%;
        padding: 0 30px;
      }
    }
  }