.block-gallery {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
  &.desktop-style-carousel {
    .inner-carousel {
      @include screen-xl-max {
        width: $mobile-container-width;
      }
      @include screen-md-max {
        width: 100%;
      }
    }
  }
  &.desktop-style-stacked {
    .block-container {
      .carousel-container {
        .inner-carousel {
          .block-gallery-splide {
            .splide-wrapper {
              display: flex;
              flex-wrap: wrap;
              .splide-slide {
                width: calc(33.3333% - 7px);
                height: 100%;
                @include screen-xl-max {
                  width: calc(50% - 5px);
                }
              }
            }
          }
        }
      }
    }
    &.mobile-style-stacked {
      @include media-breakpoint-down(md) {
        .splide-wrapper {
          flex-direction: column;
          .splide-slide {
            margin-bottom: 20px;
            &:last-child {
              margin: 0;
            }
          }
        }
        .carousel-container {
          .block-gallery-splide {
            @include screen-md-max {
              padding: 0;
            }
          }
        }
      }
    }
    &.mobile-style-carousel {
      @include media-breakpoint-down(md) {
        .block-container {
          .carousel-container {
            .inner-carousel {
              .block-gallery-splide {
                .splide-wrapper {
                  display: flex;
                  flex-wrap: unset !important;
                }
              }
            }
          }
        }
      }
    }
    @include media-breakpoint-up(md) {
      .splide__arrows {
        display: none;
        visibility: hidden;
      }
      .carousel-container {
        margin: 0;
        width: auto;
        .inner-carousel {
          position: relative;
          width: $screen-xl-max;
          margin: 0 auto;
          @include screen-xl-max {
            width: 100%;
          }
          .splide-wrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            align-items: flex-start;
            .splide-slide {
              width: calc(($screen-xl-max / 3) - 17px);
              height: auto;
              @include screen-xl-max {
                width: calc(50% - 5px);
              }
              .image-container {
                height: 250px;
              }
            }
          }
        }
      }
    }
  }
  .block-container {
    display: flex;
    flex-direction: column;
    @include screen-md-max {
      overflow: visible;
    }
    .title-container {
      position: relative;
      width: 100%;
      max-width: 100%;
      margin-bottom: 50px;
  
      .title {
        font-weight: 700;
        max-width: 100%;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .carousel-container {
      position: relative;
      margin: 0;
      width: 100%;
      flex-grow: 1;
      .inner-carousel {
        position: relative;
        width: $screen-xl-max;
        margin: 0 auto;
        @include screen-xl-max {
          width: 100%;
          overflow: visible;
        }
  
      }
      .block-gallery-splide {
        position: relative;
        width: 100%;
        padding: 10px;
        margin: 0 auto;
        overflow: hidden;
        @include screen-xl-max {
          width: 100%;
        }
        .splide-wrapper {
          overflow: visible !important;
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          transition-property: transform;
          box-sizing: content-box;
          .splide-slide {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
            overflow: hidden;
  
            .image-container {
              position: relative;
              width: 100%;
              // aspect-ratio: 2.8 / 2; // As the aspect ratio can change, there is no way to control this.
              overflow: hidden;
              background: var(--block-gallery--image-background);
  
              img {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
              }
            }
  
            .details-container {
              display: flex;
              flex-direction: column;
              padding: 20px;
              min-height: 210px;
              background: #ffffff;
              @include screen-md-max {
                min-height: unset;
              }
              .heading {
                font-weight: 700;
                margin-bottom: 5px;
              }
  
              .subheading {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .splide-button-prev.splide-button-disabled,
    .splide-button-next.splide-button-disabled {
      opacity: 0 !important;
      transition: opacity 0.3s;
    }
  
    .splide-button-prev,
    .splide-button-next {
      z-index: 999999;
      @media only screen and (max-width: 1400px) {
        display: none;
      }
    }
  
    .splide-button-prev:after,
    .splide-button-next:after {
      content: '';
      display: none;
    }
  
    .splide__arrows {
      @include screen-xl-max {
        display: none;
      }
  
      .splide__arrow {
        position: absolute;
        border: none;
        background: none;
        padding: 0px;
        background-image: initial;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99998;
        opacity: 1;
  
        &--prev {
          left: -50px;
        }
  
        &--next {
          right: -50px;
        }
  
        svg {
          color: var(--icon-colour);
          fill: currentColor;
          width: 27px;
          height: 44px;
          transform: none;
        }
      }
    }
  }
}