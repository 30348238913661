.block-button {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 30px;
    .button-container {
        display: flex;
    }
    .btn-primary,
    .btn-secondary {
        font-size: 20px;
        line-height: 32px;
        padding: 10px 25px;
    }
}