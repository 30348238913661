.block-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .block-container {
    .video-container {
      opacity: 0;
      background: var(--block-block-video--background);
      transition: opacity 0.5s;
      position:relative;
      width: 100%;
      aspect-ratio: 16 / 9;
      overflow: hidden;
      background: var(--block-block-video--background);
    }
    .video-container.active {
      opacity: 1;
    }
    .plyr {   
      width: 100%;
      height: 100%;
    }
    .plyr__video-embed {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .plyr--full-ui.plyr--video .plyr__control--overlaid {
      background: rgba(255, 255, 255, 0.5);
    }
    .plyr__control--overlaid {
      transform: translate(-50%, -50%) scale(4.85);
      transform-origin: 50% 50%;
      @include screen-md-max {
        transform: translate(-50%, -50%) scale(2.2);
      }
    }
    .plyr--playing,
    .plyr--stopped {    
      transition: opacity ease-in-out 0.5s;
    }
    .plyr__video-wrapper {
      height: 100%;
    }
    .plyr__video-wrapper iframe {
      width: 100%;
      height: 100%;
    }
    .plyr--stopped .plyr__controls {
       display: none; 
    }
  }
}