.block-mini-faq {
  position: relative;
  display: flex;
  @include screen-md-max {
    flex-direction: column;
  }
  &.accordion {
    @include screen-xl-max {
      flex-direction: column;
    }
    .heading-container {
      .heading {
        @include screen-xl-max {
          text-align: center;
        }  
      }
    }
    .questions-container {
      @include screen-xl-max {
        margin-left: 0;
      }
      .question-blocks-container {
        border-top: 1px solid var(--neutral-2);
        .question-block {
          border-bottom: 1px solid var(--neutral-2);
          margin-bottom: 0;
          padding-bottom: 0;
          transition: padding-bottom 0.2s ease-in-out;
          cursor: pointer;
          .question-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 20px;
              .question {
                margin-bottom: 0;
                max-width: 90%;
              }
              .arrow-left {
                display: block;
                position: relative;
                width: 25px;
                min-width: 25px;
                height: 25px;
                transform: rotate(0deg);
                transition: transform 0.2s ease-in-out;
                path {
                  fill: var(--primary-2);
                }
              }
          }
          .answer-wrapper {
              padding: 0 20px;
              max-height: 0px;
              overflow: hidden;
              transition: max-height 0.5s cubic-bezier(0, 1, 0, 1); // close
              > * {
                margin-bottom: 15px;
              }
          }
          &.show-block {
            padding-bottom: 20px;
            .question-wrapper {
              .arrow-left {
                transform: rotate(-90deg);
              }
            }
            .answer-wrapper {
              max-height: 999999px;
              transition: max-height 0.5s ease-in-out;
            }
          }
        }
        .btn-text {
          align-self: flex-end;
          color: #0645AD;
          padding: 20px 20px 0 0;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .block-container {
    display: flex;
    .heading-container {
      flex: 1.4;
      margin-bottom: 30px;
      .heading {
        font-family: var(--block-mini-faq--font-family);
        font-size: var(--block-mini-faq--font-size-desktop);
        line-height: var(--block-mini-faq--line-height-desktop);
        font-weight: 700;
        text-transform: var(--block-mini-faq--text-transform);
        padding: 20px;
      }
    }
    .questions-container {
      display: flex;
      flex-direction: column;
      flex: 3;
      margin-left: 40px;
      @include screen-md-max {
        margin-left: 0px;
        margin-bottom: 20px;
      }
      .question-block {
        margin-bottom: 50px;
        @include screen-md-max {
          margin-bottom: 30px;
        }
        h1,
        h2 {
          margin-bottom: 30px;
          @include screen-md-max {
          }
        }
        p {
          margin: 0;
        }
        .question {
          font-weight: 700;
          margin-bottom: 20px;
        }
        .arrow-left {
          display: none;
        }
      }
      .btn-text {
        align-self: flex-end;
        color: #0645AD;
        padding: 20px 20px 0 0;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .question-blocks-container {
      display: flex;
      flex-direction: column;
    }
  }
}