.faq-item {
  display: flex;
  flex-direction: column;
  margin-bottom: $padding;
  .question {
    font-weight: 600;
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.faq-item:last-child {
  margin: 0;
}