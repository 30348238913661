
.block-deals{
    .block-container {
        .blocks-container{
            display: flex;
            .item{            
                .spare-background {
                    position: absolute;
                    width: 1000px;
                    height: 1000px;
                    bottom: -470px;
                    right: -970px;
                    background: var(--primary-2);
                    transform: rotate(30deg);
                    z-index: 2;
                    transition: bottom $transition-03s, right $transition-03s, background $transition-03s;
                }
            }
            @include screen-lg-min {
                .item:hover {
                  .spare-background {
                    bottom: -250px;
                    right: -330px;
                    height:1000px;
                    background: var(--overlay-background);
                  }
                }
            }
        }
    }
}