.component-cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--component-cookies-background);
    z-index: 999;
    transform: translateY(100%);
    will-change: transform;
    transition: transform ease-in-out 0.5s;
    .cookies-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: $screen-xl-max;
        padding: 22px 0;
        margin: 0 auto;
        @include screen-xl-max {
            width: $mobile-container-width;
        }
        @include screen-md-max {
            flex-direction: column;
            align-items: flex-start;
        }
        .section.left {
            width: 900px;
            max-width: 900px;
            margin-right: 50px;
            @include screen-xl-max {
                width: 100%;
            }
            @include screen-md-max {
                margin-right: 0;
                margin-bottom: 20px;
            }
            p {
                margin: 0;
                padding: 0;
                color: var(--component-cookies-text-colour);
            }
            a {
                color: var(--component-cookies-link-colour);
            }
        }
    }
}

.component-cookies.active {
    transform: translateY(0%);
}