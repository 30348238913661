.block-package {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    .block-container {
      display: flex;
      flex-direction: column;
      position: relative;
      @include screen-md-max {
        width: 100%;
      }
        .inner-carousel {
          position: relative;
          width: $screen-xl-max;
          margin: 0 auto;
          @include screen-xl-max {
              width: 100%;
              overflow: visible;
          }
        }
        .bottom-caption-container {
            text-align: center;
            padding: 50px 0 0;
            width: 90%;
            margin: 0 auto;
            @include screen-md-max {
              padding: 30px 0 0;
            }
        }
        .modal-trigger {
            display: flex;
            align-items: center;
            color: var(--hyperlink);
            text-decoration: underline;
            margin-top: 2px;
            margin-left: 39px;
            cursor: pointer;
            @include screen-md-max {
              align-items: flex-start;
            }
            .modal-trigger-label {
              font-size: 18px;
              line-height: 32px;
              @include screen-md-max {
                font-size: 16px;
                line-height: 25px;
              }
            }

            &.has-icon{
              margin-left:0px;
            }
        }

        .heading.hidden, 
        .gradient.hidden {
            visibility: hidden;          
        }
        
        .grid-container {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            gap: 30px;
            width: 100%;
            margin: 0 auto;
            @include screen-xl-max {
              width: $mobile-container-width;
              justify-content: space-between;
            }
            @include screen-md-max {
                flex-direction: column;
            }
        }
        .carousel-container {
            position: relative;
            display: flex;
            flex-direction: column;
            .block-package-swiper {
              position: relative;
              width: 100%;
              padding: 10px;
              overflow: hidden;
              @include screen-xl-max {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
              }
              .swiper-wrapper {
                overflow: visible !important;
                width: 100%;
                .swiper-slide {
                    display: flex;
                    flex-direction: column;
                    height: unset !important;
                    background: var(--neutral-white);
                    box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
                    .top {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        .header {
                          position: relative;
                          display: inline-block;
                          background: var(--block-package--header-background);
                          width: 100%;
                          .gradient {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: var(--image-gradient);
                            z-index: 1;
                          }
                          .background-image {
                            position: relative;
                            width: 100%;
                            height: auto;
                          }
                          .heading-container {
                            position: absolute;
                            display: flex;
                            align-items: center;
                            bottom: $padding;
                            left: $padding;
                            z-index: 2;
                            .heading {
                              color: var(--block-package--header-colour);
                              margin: 0;
                              padding: 0;
                              margin-right: 10px;
                              text-transform: var(--block-package--heading-text-transform);
                              font-weight: var(--block-package--heading-font-weight);
                            }
                            .icon-info {
                              width: 25px;
                              height: 25px;
                              cursor: pointer;
                            }
                          }
                        }
                      }
                      .bottom {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        flex-grow: 1;
                        padding: $padding;
                        .features-list {
                          padding-bottom: var(--block-package--features-list-padding-bottom);
                          
                          ul {
                            padding: 0;
                            margin: 0;
                            font-weight: 600;
                            list-style-type: none;
                            margin-bottom: var(--package-tile--ul-margin-bottom-desktop);
                          }
                          li {
                            display: flex;
                            font-weight: 400;
                            margin-bottom: 15px;
                          }
                          li:last-child {
                            margin-bottom: 0;
                          }
                          img {
                            margin-right: 15px;
                          }
                        }
                        .button-container {
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          padding-top: var(--block-package--button-padding-top);
                          border-top: 1px solid var(--block-package--button-border);
                          .per-person {
                            display: flex;
                            flex-direction: column;
                            padding-right: 15px;
                            .from {
                              font-weight: 700;
                              margin-bottom: 0px;
                            }
                            .price {
                              font-weight: 800;
                              text-transform: lowercase;
                            }
                          }
                          
                        }
                      }
                }
            }
          }
          
          .swiper-pagination-bullet {
            pointer-events: all !important;
            width: 22px;
            height: 22px;
          }
          .swiper-pagination-bullet-active {
            background: var(--block-package--swiper-pagination-bullet-active);
          }
        }
        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
          opacity: 0 !important;
          transition: opacity 0.3s;
        }
        .swiper-button-prev,
        .swiper-button-next {
          z-index: 999999;
          @media only screen and (max-width: 1400px) {
            display: none;
          }
        }
        .swiper-button-prev:after,
        .swiper-button-next:after {
          content: '';
          display: none;
        }
        .swiper-button-prev {
          background-image: initial;
          left: -50px;
          top: 45%;
          transform: translateY(-50%);
          z-index: 99998;
        }
        .swiper-button-next {
          background-image: initial;
          right: -50px;
          top: 45%;
          transform: translateY(-50%);
          z-index: 99998;
        }
    }
    .package-modal {
        display: none;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 999999;
        .package-modal-details {
            position: relative;
            display: flex;
            flex-direction: column;
            background: var(--block-package--modal-details-background);
            color: var(--block-package--modal-details-colour);
            max-width: 600px;
            width: 600px;
            z-index: 5;
            @include screen-md-max {
              width: $mobile-container-width;
            }
            .details-container {
              display: flex;
              flex-direction: column;
              width: 100%;
              padding: 30px;
            }
        }
        .icon-modal-close {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 35px;
            height: 35px;
            z-index: 999999;
            cursor: pointer;
        }
    }
    .package-modal.active {
        display: flex;
    }
}

.block-package {
    .packages-container {
      .mobile-carousel {
        .grid-container {
          display: flex;
          @include screen-md-max {
            display: none;
          }
        }
        .carousel-container {
          display: none;
          @include screen-md-max {
            display: flex;
          }
        }
      }
    }
    .swiper-pagination {
      position: relative !important;
    }
    .swiper-pagination-bullet {
      pointer-events: all !important;
      width: 22px;
      height: 22px;
    }
    .swiper-pagination-bullet-active {
        background: var(--block-package--swiper-pagination-bullet-active);
    }

    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
      position: relative !important;
      bottom: 0 !important;
      padding: 50px 0 0 !important;
      @include screen-md-max {
        padding: 30px 0 0 !important;
      }
    }
}