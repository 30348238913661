$padding: 25px;

.block-intro-pricing {
  position: relative;
  width: 100%;
  .block-container {
    display: flex;
    align-items: flex-start;
    width: $screen-xl-max;
    margin: 0 auto;
    @include screen-xl-max {
      width: $mobile-container-width;
    }
    @include screen-md-max {
      flex-direction: column-reverse;
    }
    .body-container {
      flex: 2;
      margin-right: 40px;
    }
    .pricing-container {
      flex: 1;
      @include screen-md-max {
        width: 100%;
        margin-left: 0px;
        margin-bottom: 20px;
      }
      .pricing {
        width: 100%;
        .title-container {
          background: var(--block-intro-pricing--pricing-title-background);
          color: var(--block-intro-pricing--pricing-title-colour);
          padding: $padding;
          .title {
            text-transform: uppercase;
          }
          p {
            margin: 0;
            padding: 0;
          }
        }
        .footer-text {
          margin-top: 20px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          text-align: center;
        }
        .locations-dropdown-container {
          background: var(--block-intro-pricing--locations-dropdown-container-background);
          padding: $padding;
        }
        .locations-container {
          position: relative;
          display: flex;
          padding: $padding;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
          overflow: hidden;
          .details-container {
            display: none;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;
            p {
              margin: 0;
              padding: 0;
            }
            .details-row {
              display: flex;
              margin-bottom: $padding;
              p {
                line-height: 24px;
              }
              .title {
                font-weight: 700;
                text-transform: uppercase;
              }
              .price {
                font-weight: 600;
              }
              .activity-subtitle {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
              }
            }
            .column-1 {
              flex: 1.5;
            }
            .column-2,
            .column-3 {
              text-align: center;
              flex: 1;
              .line-1 {
                font-weight: 700;
                text-transform: uppercase;
              }
              .line-2 {
                font-weight: 600;
              }
            }
            .details-container-top {
              display: flex;
              width: 100%;
              padding-bottom: $padding;
              margin-bottom: $padding;
              border-bottom: 1px solid var(--block-intro-pricing--details-container-border);
              .top-row {
                display: flex;
                flex-direction: column;
                text-align: center;
                p {
                  line-height: 24px;
                }
              }
            }
            .details-container-bottom {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              width: 100%;
              .venue-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                opacity: 1;
                height: auto;
                transition: opacity 0.5s;
                .venue {
                  flex-grow: 1;
                }
                .book-button-container {
                  display: flex;
                  justify-content: center;
                }
              }
              .venue-container.inactive {
                opacity: 0;
                height: 0;
                overflow: hidden;
              }
            }
            .details-activity-choices {
              display: flex;
              .activity-choices {
                font-weight: 600;
                margin: 0;
                padding: 0;
              }
            }
          }
          .please-select-container {
            display: none;
          }
          .please-select-container.active {
            position: relative;
            width: 100%;
            height: 450px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--block-intro-pricing--please-select-active-background);
            z-index: 1;
            .select-message {
              max-width: 200px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}